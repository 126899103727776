import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import UserResponses from "/src/views/UserResponses/UserResponses";
import UserResponsesItemDetail from "/src/views/UserResponses/UserResponsesItemDetail/UserResponsesItemDetail";
import { Skeleton } from "antd";
import { isGradingWithAIInProgress } from "/src/lib/utils/helperMethods";

const UserResponseFeedback = (props) => {
  console.log("UserResponseFeedback props ==>", props)
  const { graderAttrs } = props;
  const { path, url } = useRouteMatch();
  console.log("graderAttrs 61", graderAttrs);

  const experienceUser = graderAttrs.experienceUser || {}
  const isGradingWithAIInProgressForEU = isGradingWithAIInProgress(experienceUser);

  let finalRender;

  if(isGradingWithAIInProgressForEU) {
    finalRender = <Skeleton className="bg-lavender" style={{ borderRadius: '8px', padding: '10px' }} active />
  } else if (graderAttrs.userResponse && graderAttrs.userResponse.id) {
    console.log(
      "graderAttrs 66 should render UserResponses",
      graderAttrs.userResponse,
      graderAttrs.experienceUser
    );
    finalRender = (
      <Route
        path={`${path}`}
        render={(routeProps) => (
          <UserResponses
            graderAttrs={graderAttrs}
            createUpdateParams={{
              experience_user_id: graderAttrs.experienceUser.id,
            }}
            resourceId={graderAttrs.userResponse.id}
            isRoute={false}
            {...routeProps}
            config={{
              routes: { fetch: false },
              itemDetail: { widget: UserResponsesItemDetail },
            }}
          />
        )}
      />
    );
  }

  return <React.Fragment>{finalRender}</React.Fragment>;
};

export default UserResponseFeedback;