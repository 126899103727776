import React from "react";
import PropTypes from "prop-types";
import { Table, Card, List, Avatar, Space, Tag, Tooltip, Popconfirm, Button } from "antd";
import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { Link, useRouteMatch } from "react-router-dom";
import { checkMob } from "../../../../src/lib/utils/helperMethods";
import "../Groups.scss";
import GroupCard from "./GroupCard";
import dayjs from "dayjs";
import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { actions as groupReduxActions } from "/src/views/Groups/redux";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi"
import { useDispatch } from "react-redux";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";


const GroupsList = (props) => {
  console.log("groups list props===>", props);
  
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { dataSource, permission, currentUser, deleted, history} = props;
  const mrIntl = useTranslate();
  const tableDataSource = [];
  for (let index = 0; index < dataSource.length; index++) {
    let objToPush = {};
    objToPush.id = dataSource[index].id;
    // objToPush.name = dataSource[index].name
    objToPush.name = (
      <Link to={`${url}/${dataSource[index].id}/members`}>
        {dataSource[index].id} - {dataSource[index].name}
      </Link>
    );
    // objToPush.label = dataSource[index].label;
    // objToPush.org_id = dataSource[index].org_id;
    // objToPush.org_programme_id = dataSource[index].org_programme_id
    objToPush.org_programme_id = dataSource[index].org_programme_abbr;
    // objToPush.grade_id = dataSource[index].grade_id
    objToPush.grade_id = dataSource[index].grade_name;
    // objToPush.subject_id = dataSource[index].subject_id
    objToPush.subject_id = dataSource[index].subject_name;
    // objToPush.user_id = dataSource[index].user_id
    
    // objToPush.group_teacher_ids = dataSource[index].group_teacher_ids
    let groupTeachers = [];
    if (dataSource[index].group_teachers) {
      dataSource[index].group_teachers.map((teacher, i) => {
        console.log("teacher", teacher);
        let fullName = (
          <Tag>
            {teacher[0]} {teacher[1]}
          </Tag>
        );
        groupTeachers.push(fullName);
      });
    }
    objToPush.group_teachers = groupTeachers;
    objToPush.group_students_count = <Link to={`${url}/${dataSource[index].id}/members`}>{dataSource[index].group_students_count} {mrIntl("CommonText.students")}</Link>;
    objToPush.created_by = dataSource[index].created_by;
    objToPush.timestamps = []
    // objToPush.timestamps.push(<Tag>{dayjs(dataSource[index].created_at).format("MMM DD, YYYY")}</Tag>)
    objToPush.timestamps.push(<Tag>{dayjs(dataSource[index].updated_at).format("MMM DD, YYYY")}</Tag>)
    // objToPush.archived = dataSource[index].archived
    
    if (permission.group.update) {
      objToPush.actions = (
        <ActionBtns
          edit={true}
          delete={true}
          deleted={props.deleted}
          item={dataSource[index]}
          id={dataSource[index].id}
        />
      );
    }
    tableDataSource.push(objToPush);
  }

  const columns = [
    {
      title: mrIntl("CommonText.class_name"),
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    // {
    //   title: "Label",
    //   dataIndex: "label",
    //   key: "label",
    // },
    // {
    //   title: "org_id",
    //   dataIndex: "org_id",
    //   key: "org_id",
    // },
    {
      title: mrIntl("CommonText.program"),
      dataIndex: "org_programme_id",
      key: "org_programme_id",
    },
    {
      title: mrIntl("CommonText.grade"),
      dataIndex: "grade_id",
      key: "grade_id",
    },
    {
      title: mrIntl("CommonText.subject"),
      dataIndex: "subject_id",
      key: "subject_id",
    },
    
    // {
    //   title: 'teacher_ids',
    //   dataIndex: 'group_teacher_ids',
    //   key: 'group_teacher_ids',
    // },
    {
      title: mrIntl("GroupsList.teachers"),
      dataIndex: "group_teachers",
      key: "group_teachers",
    },
    {
      title: mrIntl("CommonText.students"),
      dataIndex: "group_students_count",
      key: "group_students_count",
    },
    {
      title: mrIntl("GroupsList.created_by"),
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: mrIntl("CommonText.last_updated"),
      dataIndex: "timestamps",
      key: "timestamps",
    },
  ];

  if (permission.group.update) {
    columns.push({
      title: mrIntl("CommonText.actions"),
      dataIndex: "actions",
      key: "actions",
    });
  }

  // return (
  //   <>
  //     {checkMob() ? (
  //       <>
  //         <GroupCard dataSource={dataSource} />
  //       </>
  //     ) : (
  //       <Table
  //         dataSource={tableDataSource}
  //         columns={columns}
  //         pagination={false}
  //         // showHeader={false}
  //         // title={() => "Classes"}
  //         // rowClassName={() => "clickable-table-row"}
  //         onRow={(record, rowIndex) => {
  //           return {
  //             // onClick: event => {history.push(`${url}/${record.id}/tests`)}, // click row
  //             // onDoubleClick: event => {}, // double click row
  //             onContextMenu: event => {}, // right button click row
  //             // onMouseEnter: event => {}, // mouse enter row
  //             // onMouseLeave: event => {}, // mouse leave row
  //           };
  //         }}
  //       />
  //     )}
  //   </>
  // );

  const Title = ({item}) => (
    <Space className="title">
      {item.archived && <Tooltip title={mrIntl("GroupsList.this_class_is_archived")}><span className="archive-icon"><BiArchiveIn /></span></Tooltip>}
      <span>{item.name}</span>
      {/* <Tooltip title={item.source_id ? `MB UUID (${item.uuid})`:  ""}><span>{item.name}</span></Tooltip> */}
    </Space>
  )

  const Description = ({item}) => {
    console.log("item in descriptions ==>", item)
    const groupTeachers = item.group_teachers || [];

    const formatTeacherName = ([firstName, lastName]) => lastName ? `${firstName} ${lastName}` : firstName;
    const firstTeacher = groupTeachers[0] && formatTeacherName(groupTeachers[0]);
    const allTeachersNames = groupTeachers.map(formatTeacherName).join(", ");

    const teacherNamesWithTooltip = groupTeachers.length > 1 ? (
      <div onClick={(e) => e.stopPropagation()}>
        <Tooltip title={allTeachersNames}>
          <span>{firstTeacher}...</span>
        </Tooltip>
      </div>
    ) : (
      <span>{firstTeacher}</span>
    );

    return (
      <Space className="description">
        {/* Add teachers list/count */}
        {/* <span>{item.group_teachers.length} teacher{item.group_teachers.length != 1 && <span>s</span>}</span> */}
        <span>{teacherNamesWithTooltip}</span>
        {/* {<span>{item.created_by}</span>} */}
        {/* {<span>{item.email}</span>} */}
        {/* {isStudent && item.grade && <span>{item.grade.name}</span>} */}
        {<span>{dayjs(item.updated_at).format("MMM DD, YYYY")}</span>}
        {/* <span><InfoCircleOutlined/></span> */}
      </Space>
    )
  }

  const ProfileIcon = ({item}) => (
    <Tooltip title={item.org_programme_is_mb ? `MB(${item.source_id})`:  ""}><Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{item.name.substr(0,1).toUpperCase()}</Avatar></Tooltip>
    
  )

  const ExtraInfo = ({item}) => {
    const extra = [];
    item.org_programme_abbr && extra.push(<Tag>{item.org_programme_abbr}</Tag>)
    item.subject_label && extra.push(<Tooltip title={mrIntl("CommonText.subject")}><Tag>{item.subject_label}</Tag></Tooltip>)
    item.subject_group_label && extra.push(<Tooltip title={mrIntl("CommonText.subject_group")}><Tag>{item.subject_group_label}</Tag></Tooltip>)
    item.grade_label && extra.push(<Tag>{item.grade_label}</Tag>)
    item.group_students && extra.push(<Tag>{item.group_students.length} {item.group_students.length !== 1 ? <span>{mrIntl("CommonText.students")}</span> : mrIntl("CommonText.student")}</Tag>)
    return extra;
  }

  const ExtraActions = ({item, permission, currentUser}) => {
    const actions = [];
    permission.group.delete && !currentUser.org.is_mb && item.archived && actions.push(<Tooltip title={mrIntl("GroupsList.unarchive")} placement="bottom">
      <Button type="text" shape="circle" icon={<BiArchiveOut />} onClick={() => {
        dispatch(groupReduxActions.update({id: item.id, archived: false}, {success: {showMessage: true, message: "Class unarchived"}, }));
      }}></Button>
    </Tooltip>)
    return actions;
  }

  const extraActions = (item, permission, currentUser) => {
    const actions = [];
    permission.group.delete && !currentUser.org.is_mb && item.archived && actions.push(<Tooltip title={mrIntl("GroupsList.unarchive")} placement="bottom">
      <Button type="text" shape="circle" icon={<BiArchiveOut />} onClick={() => {
        dispatch(groupReduxActions.update({id: item.id, archived: false}, {success: {showMessage: true, message: mrIntl("GroupsList.class_unarchived")}, }));
      }}></Button>
    </Tooltip>)
    return actions;
  }

  let finalRender = null;

  finalRender = (
    <React.Fragment>
      <List 
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => (
          // <CustomGroupListItem item={item} currentUser={currentUser} deleted={deleted} permission={permission} history={history}/>
          
          // sending null for SA to stop clicking and opening - @nitin change this groups_list condition in CustomListItem - action should be send from here
          <CustomListItem item={item} type={"groups_list"} linkToGo={(currentUser.role !== "superadmin" && !item.archived) ? `${url}/${item.id}` : null} title={<Title item={item} />} description={<Description item={item} />} avatar={<ProfileIcon item={item} />} extraInfo={<ExtraInfo item={item} />} extraActions={extraActions(item, permission, currentUser)} isListActionsMenu={true} currentUser={currentUser} edit={permission.group.update && !item.org_programme_is_mb && !currentUser.org.is_edlink && !item.archived && currentUser.role !== "superadmin"} deleted={deleted} deletedAllow={permission.group.delete && !item.org_programme_is_mb && !item.archived && currentUser.role !== "superadmin"} permission={permission} history={history}/>
        )}
      />
      {/* extraActions={<ExtraActions item={item} currentUser={currentUser} permission={permission} />} */}
    </React.Fragment>
  );

  return finalRender;


};
GroupsList.defaultProps = {};

GroupsList.propTypes = {};

export default GroupsList;
