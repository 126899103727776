import React from "react";
import { Table,Tag, Tooltip, Typography } from "antd";
import { useRouteMatch } from "react-router-dom";
import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import {InfoCircleOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const { Title } = Typography;

const FeaturesList = (props) => {
  console.log("FeaturesList props", props);
  const { dataSource } = props;
  const tableDataSource = [];
  const mrIntl = useTranslate()
  for (let index = 0; index < dataSource.length; index++) {
    let objToPush = {};
    objToPush.id = dataSource[index].id;
    objToPush.name = dataSource[index].name;
    objToPush.code = dataSource[index].code;
    const plans = dataSource[index]?.plans || [];
    objToPush.plans = (
      <>
        {plans.length > 0 ? (
          plans.map((plan) => (
            <Tag color="default" key={`plan-subsciption-count-${plan?.id}`}>
              <span>{plan.name} - {plan.subscriptions_count}</span>
            </Tag>
          ))
        ) : (
          "0"
        )}
      </>
    );
    
    const currentStatus = dataSource[index].status
    objToPush.status = (
      <>
        <Tag
          color={
            currentStatus === "development"
              ? "warning"
              : currentStatus === "stable"
                ? "default"
                : currentStatus === "released"
                  ? "success"
                  : "default"
          }
        >
          {currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1)}
        </Tag>
        {currentStatus === "released" &&
          <Tooltip
            title={`Release date: 
            ${dayjs(dataSource[index]?.updated_at)?.format('DD-MM-YYYY, HH:mm')}`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        }
      </>
    );
    objToPush.count = dataSource[index].status === "released"
      ? "All"
      : dataSource[index]?.org_features?.filter(feature => feature.enabled === true).length;
    // objToPush.tiers_json = JSON.stringify(dataSource[index].tiers_json)
    objToPush.actions = (
      <ActionBtns
        edit={true}
        delete={true}
        deleted={props.deleted}
        item={dataSource[index]}
        id={dataSource[index].id}
      />
    );
    tableDataSource.push(objToPush);
  }

  const columns = [
    {
      title: <Title level={5}>{mrIntl("CommonText.id")}</Title>,
      dataIndex: "id",
      key: "id",
    },
    {
      title: <Title level={5}>{mrIntl("CommonText.name")}</Title>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <Title level={5}>{mrIntl("CommonText.code")}</Title>,
      dataIndex: "code",
      key: "code",
    },
    {
      title: <Title level={5}>{mrIntl("FeaturesList.status")}</Title>,
      dataIndex: "status",
      key: "status",
    },
    {
      title: <Title level={5}>Subscription plans count</Title>,
      dataIndex: "plans",
      key: "plans",
    },
    {
      title: <Title level={5}>{mrIntl("FeaturesList.enabled_orgs_count")}</Title>,
      dataIndex: "count",
      key: "count",
    },
    // {
    //   title: 'tiers_json',
    //   dataIndex: 'tiers_json',
    //   key: 'tiers_json',
    // },
    {
      title: <Title level={5}>{mrIntl("CommonText.actions")}</Title>,
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const { url } = useRouteMatch();
  console.log("exp list item url", url);
  return (
    <Table dataSource={tableDataSource} columns={columns} pagination={false} />
  );
};

export default FeaturesList;
