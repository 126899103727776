
import { displayPoints, isGradingWithAIInProgress, isStringEmpty } from "/src/lib/utils/helperMethods";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateClassName } from "/src/views/Experiences/ExperienceHelperMethods";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { MessageOutlined, EditOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Button, Skeleton, Space, Tooltip } from "antd";
import { BiCommentDetail } from "react-icons/bi";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { showConvertedAchievementLevelSelector } from "../selector";
import { currentUserSelector } from "/src/views/Auth/Login/selector";

const NonMemoizedExperienceUserOverallPoints = (props) => {
  console.log("NonMemoizedExperienceUserOverallPoints =====>", props);
  const { eu, exp_cas, rubricType, inExperienceUserHeader, eu_scas, show_converted_al } = props
  const dispatch = useDispatch();
  const [tempVisible, setTempVisible] = useState(true);
  const mrIntl = useTranslate();
  const showConvertedAchievementLevel = useSelector(showConvertedAchievementLevelSelector());
  const isGradingWithAIInProgressForEU = isGradingWithAIInProgress(eu);
  const currentUser = useSelector(currentUserSelector())
  const media_comments = eu.custom_fields && eu.custom_fields.media_comments

  let finalRender = []
  let pointsSpanInnerRender = []

  const isStudentOrParent = currentUser.role === "student" || currentUser.role === "parent";

  // useEffect(() => {
  //   if(inExperienceUserHeader){
  //     setTimeout(() => {
  //       setTempVisible(false)
  //     }, 3000);
  //   }
  // }, []);

  if (isGradingWithAIInProgressForEU) {
    return <Skeleton.Input className={`skeleton-loading-scroll ${isGradingWithAIInProgressForEU ? "bg-lavender" : ""}`} active />
  }

  if(rubricType === "points") {
    pointsSpanInnerRender.push(<span className="ur-cell-points-span">{displayPoints(eu.points, false, true)}</span>)
  } else if(rubricType === "comments_only") {
    if(!isStringEmpty(eu.comments) || (media_comments && media_comments.attachments.length > 0)) {
      pointsSpanInnerRender.push(<BiCommentDetail />)
    } else {
      pointsSpanInnerRender.push("-")
    }
  } else {
    exp_cas.forEach((ca, k) => {
      console.log("GradingSummaryGrid eu", eu);
      let eu_scas = eu.submission_criterium_associations_attributes || []; // submission_criterium_associations_attributes should never bu null/undefined from BE if exp_cas exist
      let currentSca = eu_scas.find((sca) => sca.criterium_id === ca.criterium_id)

      if (currentSca) {
        let isNa = false;
        let naSpanRender = <span style={{"fontSize": "10px"}}>N/A</span>
        if ((rubricType === "myp_achievement_level" || show_converted_al) && currentSca.custom_fields && currentSca.custom_fields.is_na === true) {
          isNa = true;
        }
        let currentScaPoints = currentSca.points
        if (show_converted_al){
          currentScaPoints = null
          if (currentSca.custom_fields){
            currentScaPoints = currentSca.custom_fields.converted_achievement_level
          }
        }
        console.log("NonMemoizedExperienceUserOverallPoints eu_scas currentScaPoints", currentScaPoints, currentSca);

        let cTitle = <sup>{ca.custom_fields.title}</sup>;
        let displayPts = displayPoints(currentScaPoints, false, true)
        pointsSpanInnerRender.push(<>{cTitle}{currentScaPoints != null ? displayPts : isNa ? naSpanRender : "-"}&nbsp;</>)
      }
    });
  }

  // if(props.onClick){
  //   finalRender.push(
  //     <FullscreenOutlined />
  //   )  
  // }
  finalRender.push(
    <span className="ur-cell-points-span">{pointsSpanInnerRender}</span>
  )

  if ((!isStringEmpty(eu.comments) || (media_comments && media_comments.attachments.length > 0)) && rubricType !== "comments_only") {
    finalRender.push(
      <div className={"ur-cell-icons ur-cell-icon-comment"}>
        <BiCommentDetail />
      </div>
    )
  }

  const shouldShowPointsInHeader = !isStudentOrParent || (rubricType === "comments_only" || rubricType === "points") ;

  return inExperienceUserHeader ? (
    shouldShowPointsInHeader && (
      <Tooltip
        title={mrIntl("ExperienceUserOverallPoints.overall_grading")}
        // visible={tempVisible}
        placement="right"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        <Button
          className={"overall-points-inside-eu-header"}
          onClick={props.onClick ? props.onClick : () => {}}
        >
          <Space>{finalRender}</Space>
        </Button>
      </Tooltip>
    )
  ) : (
    <Space
      key={`total-points-${eu.id}`}
      className={`exp-users-list-points-wrapper question-cell-color-${generateClassName(
        eu,
        rubricType,
        "experience_user"
      )}`}
      size={0}
    >
      {finalRender}
    </Space>
  );
}

const ExperienceUserOverallPoints = memo(NonMemoizedExperienceUserOverallPoints)
export default ExperienceUserOverallPoints;