import React, { useEffect, useState } from "react";

import { ShowText, ShowTextContent, InputArea, CustomErrorMessage, WordCount } from '/src/components/UI/Segment/UIHelper'
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE"
import { CKETextAnswerTools } from "/src/components/UI/CKEditor/CKEConfig"
import { getUniqueId, isStringEmpty } from "/src/lib/utils/helperMethods";
import { useAnnotation } from "/src/lib/Annotator/TextAnnotation";
import { maxCharacters, validateText, allowedPasteCharactersLimit } from "/src/lib/utils/helperMethods"
import { cloneDeep, filter, sortBy } from "lodash"
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { Alert, Card } from "antd";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, printExperienceSelector, printModeSelector, printOptionsSelector } from "/src/views/Experiences/selector";
import { annotationsSelector } from "/src/views/Annotations/selector";
import { enabledFeaturesSelector } from "../../Auth/Login/selector";

const CKETextAnswer = props => {
  console.log("CKETextAnswer props ===>", props);
  const { answerMode, presentationMode, segmentSettings, attempt_status = {}, triggerStateUpdate, ckeConfig = {}, annotationOptions = {}, setRenderMath, numberOfLinesForAnswerSpace, extraClass = "", attachmentsCount} = props
  const mrIntl  = useTranslate()
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const printExperience = useSelector(printExperienceSelector());
  const printMode = useSelector(printModeSelector())
  const printOptions = useSelector(printOptionsSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const activeExperienceSettings = activeExperience.settings || {}
  let isPaperMode = activeExperienceSettings.mode === "paper"
  
  if (printMode) {
    isPaperMode = printExperience?.experience?.settings?.mode === "paper"
  }

  let attemptStatusObject = {
    words: attempt_status.words || 0,
    characters: attempt_status.characters || 0,
    text_answer_attempted: attempt_status.text_answer_attempted || false,
  }

  const [state, setState] = useState(props.text_answer)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject)

  const [annotationsListBySequence, setAnnotationsListBySequence] = useState([]);

  let content
  if (presentationMode) {
    content = props.text_answer
  } else {
    content = state
  }

  let spacesOnStudentAnswer = [];
  if (printOptions && printOptions.with_space_for_answers === "true") {
    numberOfLinesForAnswerSpace &&
      [...Array(Math.min(numberOfLinesForAnswerSpace, 20))].map((item, i) =>
        spacesOnStudentAnswer.push(
          <hr
            className="space-on-student-answer"
            key={`space-on-student-answer-${i}`}
          />
        )
      );
  }
  console.log("CKETextAnswer props ==>", numberOfLinesForAnswerSpace, printOptions, spacesOnStudentAnswer)


  // const wordCount = ckeConfig.wordCount || {}

  // Can use same word count code block for question form view
  const [wordCount, setWordCount] = useState({
    maxCharacters: maxCharacters,
    onUpdate: (stats) => {
      console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`)
      
      const isLimitExceeded = stats.characters > maxCharacters
      const isCloseToLimit = !isLimitExceeded && stats.characters > maxCharacters * .8;

      setWordCount({
        ...wordCount,
        ...stats,
        isLimitExceeded: isLimitExceeded,
        isCloseToLimit: isCloseToLimit
      })

      if (isLimitExceeded) {
      }
    }
  })
  console.log("Word count on render ==>", wordCount, wordCount.words)

  let ckeTools = {
    ...CKETextAnswerTools,
    ...ckeConfig,
    wordCount: wordCount,
  }

  // APL-5586 - Disable paste characters limit for student answer (DPS School demanded)
  if ((activeExperienceSettings.mode === "offline" || activeExperienceSettings.mode === "online_secure") && !enabledFeatures.disable_paste_characters_limit_student_answer) {
    ckeTools = {
      ...ckeTools,
      pasteCharactersLimit: allowedPasteCharactersLimit,
    }
  }

  const validationRules = {
    isLimitExceeded: wordCount.isLimitExceeded,
    maxCharsMessage: mrIntl("CKETextAnswer.text_limit_reached_msg")
  }
  const validation = validateText(content, validationRules)

  const valid = validation.success
  let errorMessage = null
  let className = `multiline-input-area text-answer-input-area ${extraClass}`

  if (!valid) {
    className += ' show-error'
    errorMessage = validation.messages[0]
  }
  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }, [])

  useEffect(() => {
    if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          text_answer: cloneDeep(state),
          attempt_status: cloneDeep(attemptStatus)
        }

        props.onChange(result)
        // props.onChange(state)
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (newState) => {
    setState(newState)

    if (!valid) {
      return
    }
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
  }

  const onTextChanged = (data, extra = {}) => {
    const newState = data

    let isTextChanged = false
    if (data) {
      isTextChanged = true
    }

    const wordStats = extra.wordCount || wordCount
    console.log("Word count on 114 text changed ==>", wordCount, wordCount.words, wordCount.characters)
    if (answerMode) {
      setAttemptStatus({
        ...attemptStatus,
        words: wordStats.words || 0,
        characters: wordStats.characters || 0,
        text_answer_attempted: isTextChanged,
      })
    }
    saveState(newState)
  }

  
  const textAnswerRef = useAnnotation(annotationOptions, setAnnotationsListBySequence)
  console.log("CKETextAnswer textAnswerRef ==>", textAnswerRef, props);
  const showTextContent = <ShowTextContent
    key={`show-text-content-${props.unique_segment_identifier}-${annotationOptions.allowed ? "annotation-enabled" : "annotation-disabled"}`}
    ref={textAnswerRef}
    className={`show-text-answer ${printMode ? "remove-background-color" : ""}`}
    dangerouslySetInnerHTML={{ __html: content }}
  ></ShowTextContent>

  return answerMode ? (
    <>
      {printMode && printOptions.with_space_for_answers === "true" && (
        <div className="space-student-answer">{spacesOnStudentAnswer}</div>
      )}
      {!printMode && (
        <InputArea className={`${className} m-t-20`}>
          <DefaultCKE
            tools={ckeTools}
            data={content}
            onTextChanged={onTextChanged}
            editorFooterConfig={{
              visible: true,
              extraInfo: `Words: ${wordCount.words}`
            }}
          ></DefaultCKE>
          {/* <WordCount wordCount={wordCount.words}></WordCount> */}
          <CustomErrorMessage
            className="error-message"
            message={errorMessage}
          />
        </InputArea>
      )}
    </>
  ) : (
    <div>
      {/* <RenderHtml text={content} /> */}
      {attempt_status.text_answer_attempted ? (
        <>
          {printMode ? (
            <>
              <Card
                className="print-answer-explanation"
                title={"Student's answer"}
              >
                {showTextContent}
                {/* <RenderHtml
              key={`render-html-${props.unique_segment_identifier}`}  text={content} htmlRef={textAnswerRef} /> */}
              </Card>
              {annotationsListBySequence.length > 0 && (
                <Card
                  className="print-annotations"
                  title={"Annotations"}
                  style={{ marginTop: "20px" }}
                >
                  {annotationsListBySequence.map((data, index) => (
                    <p>{data}</p>
                  ))}
                </Card>
              )}
            </>
          ) : (
            <>
              <h4 className="students-answer-heading">
                <MrTranslate id={"CKETextAnswer.students_answer_heading"} />
              </h4>
              {showTextContent}
              <WordCount wordCount={attempt_status.words}></WordCount>
            </>
          )}
        </>
      ) :  attachmentsCount > 0 ? null : (
        <>
          {printMode ? (
            <Card
              className="print-answer-explanation no-answer"
              title={"Student's answer"}
            >
              {mrIntl("CKETextAnswer.student_did_not_write_an_answer_msg")}
            </Card>
          ) : (
            <Alert
              type="warning"
              showIcon
              message={mrIntl(
                "CKETextAnswer.student_did_not_write_an_answer_msg"
              )}
            />
          )}
        </>
      )}
    </div>
  );
};
export default CKETextAnswer;