import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Upload, Modal, Image, Tooltip, Switch, Space } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { UploadOutlined, InboxOutlined, PlusOutlined, CameraOutlined} from "@ant-design/icons";
import axios from "axios";
import ThumbnailAttachments from "../Attachments/ThumbnailAttachments/ThumbnailAttachments";
import PreviewAttachment from "../Attachments/PreviewAttachment";
import {
  showInfoModal,
  showConfirmModal,
} from "/src/components/UI/Segment/UIHelper";
import { checkMob } from "/src/lib/utils/helperMethods";
import { getInternetStatusSelector } from "/src/views/Experiences/selector";

// import * as tus from "tus-js-client";
import loadable from "@loadable/component";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const tusLib = loadable.lib(() =>
  import(/* webpackChunkName: "tus" */ "tus-js-client")
);

const internetStatusSelector = getInternetStatusSelector();
const { Dragger } = Upload;

const SelectFromComputer = (props) => {
  console.log("select from computer props===>", props.mediaFormSetting);
  const { mediaFormSetting, setMediaFormSetting, onSaveMedia, extractQuestionsFromPdf, onCancelMedia } = props;
  const internetStatus = useSelector(internetStatusSelector);
  const mrIntl = useTranslate()
  const defaultFileList = mediaFormSetting.selectConfig.defaultFileList ? [...mediaFormSetting.selectConfig.defaultFileList] : [];
  const controlledFileList = mediaFormSetting.selectConfig.controlledFileList ? [...mediaFormSetting.selectConfig.controlledFileList] : [];
  const notDeletableFiles = mediaFormSetting.selectConfig.notDeletableFiles || [];
  const multiple = mediaFormSetting.selectConfig.multiple ?? true;
  const [fileSetting, setFileSetting] = useState({
    files: defaultFileList || [],
    fileList: defaultFileList || [],
  });
 
  useEffect(() => {
    if (fileSetting.fileList.length === 0 && defaultFileList.length === 0 && mediaFormSetting.modal ) {
      const newMediaFormSetting = {
        ...mediaFormSetting,
        okDisabled: true,
      };
      setMediaFormSetting(newMediaFormSetting);
    }
  }, [fileSetting.fileList.length]);
  
  useEffect(() => {
    if (mediaFormSetting.triggerResetToDefault) {
      setFileSetting({ 
        ...fileSetting,
        files: defaultFileList,
        fileList: defaultFileList
      })
    }
  }, [defaultFileList.length, mediaFormSetting.triggerResetToDefault])


  useEffect(() => {
    if (controlledFileList && mediaFormSetting.triggerFileListChange) {
      setFileSetting({ 
        ...fileSetting,
        files: controlledFileList,
        fileList: controlledFileList
      })
    }
  }, [mediaFormSetting.triggerFileListChange])
  
  useEffect(() => {
    if (mediaFormSetting.triggerSaveAction) {
      handleOk();
    }
  }, [mediaFormSetting.triggerSaveAction]);

  useEffect(() => {
    tusLib.load().then((module) => {
      window.tus = module;
    });
  }, []);

  const resetUploadSettings = () => {
    setMediaFormSetting(prevMediaFormSetting => {
      return {
        ...prevMediaFormSetting,
        okDisabled: false,
        fileUploading: false
      }
    });
    if (mediaFormSetting.showUploadList === false) {
      setFileSetting(prevFileSetting => ({
        ...prevFileSetting,
        fileList: [],
      }));
    }
  }

  const token = localStorage.getItem("token");
  let uploadProps = {
    name: "file",
    listType: "picture-card",
    action:
      (import.meta.env.VITE_API_URL || "/backend/api/v1/") +
      "attachments" +
      (mediaFormSetting.extraParams ? `?${mediaFormSetting.extraParams}` : ""),
    headers: {
      token: token,
    },
    withCredentials: false,
    multiple: multiple,
    disabled: mediaFormSetting.disabled ? mediaFormSetting.disabled : false,
    fileList: fileSetting.fileList,
    beforeUpload: (file, fileList) => {
      //skipFocusLost => Skipping the focus lost modal when camera capture modal will open, It will skip for only 2 sec when user done the camera campture. Now skipFocusLost calling from here because this function trigger when  user captured the image and camera modal going to be hidden.
      if (mediaFormSetting.skipFocusLost) {
        mediaFormSetting.skipFocusLost();
      }
      console.log("Form Setting before Upload ==>", mediaFormSetting, file);
      let validationFailed = false;
      let validationMessage = "";
      let fileName = file.name || "";
      let file_ext = fileName
        .substr(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      console.log(
        "Supported extensions and type ==>",
        mediaFormSetting.supportedExtensions,
        file_ext,
        mediaFormSetting.supportedTypes,
        file.type
      );
      if (
        mediaFormSetting.supportedExtensions &&
        (mediaFormSetting.supportedExtensions.indexOf(file_ext) == -1 ||
          !file_ext)
      ) {
        validationMessage = `${file.name} file type is not supported! Please check extension.`;
        validationFailed = true;
      } else if (
        mediaFormSetting.supportedFormats &&
        mediaFormSetting.supportedFormats.indexOf(file_ext) == -1
      ) {
        validationMessage = `${file.name} file type is not supported! Please check extension.`;
        validationFailed = true;
      } else if (
        mediaFormSetting.supportedTypes &&
        (mediaFormSetting.supportedTypes.indexOf(file.type) == -1 || !file.type)
      ) {
        validationMessage = `${file.name} file type is not supported!`;
        validationFailed = true;
      } else if (
        file.size / 1024 / 1024 <
        parseInt(mediaFormSetting.selectConfig.minSize)
      ) {
        validationMessage = `${file.name} file must greater than ${mediaFormSetting.selectConfig.minSize}!`;
        validationFailed = true;
      } else if (
        file.size / 1024 / 1024 >
        parseInt(mediaFormSetting.selectConfig.maxSize)
      ) {
        validationMessage = `${file.name} file must be less than ${mediaFormSetting.selectConfig.maxSize}!`;
        validationFailed = true;
      }

      console.log("validation status ==>", validationFailed, validationMessage);
      if (validationFailed) {
        // message.error(validationMessage)
        file.status = "error";
        file.customMessage = true;
        file.response = validationMessage;
        message.error(validationMessage);
        return Upload.LIST_IGNORE;
      }

      let newFileList = [...fileSetting.fileList];
      const limit = mediaFormSetting.limit;
      let maxLimitReached = newFileList.length >= limit;
      if (!maxLimitReached) {
        newFileList.push(file);
        setFileSetting({
          ...fileSetting,
          fileList: newFileList,
        });
      } else {
        message.error(mediaFormSetting.limitMessage || "Max upload limit reached");
        return Upload.LIST_IGNORE;
      }

      if (mediaFormSetting.uploadOnOk) {
        setMediaFormSetting((prevMediaFormSetting) => {
          return {
            ...prevMediaFormSetting,
            okDisabled: false,
          }
        });
        return false;
      } else {
        setMediaFormSetting((prevMediaFormSetting) => {
          return {
            ...prevMediaFormSetting,
            okDisabled: true,
            fileUploading: true
          }
        });
     
        return true;
      }
    },
    onChange(info) {
      console.log("on change info ==>", info);
      const limit = mediaFormSetting.limit;

      let newFileList = info.fileList;
      if (limit == 1) {
        // newFileList = newFileList.slice(-limit);
        newFileList = [info.file]
      }
      if (limit) {
        newFileList = newFileList.slice(0, limit);
      }

      if (info.file.status != "done") {
        setFileSetting({
          ...fileSetting,
          fileList: newFileList,
        });
      }
      // Using newFileList to check if all files are uploaded before running onChangeSaveFiles for multiple uploads
      const uploadingFiles = filterFilesByStatus(newFileList, "uploading");

      if (info.file.status === "done") {
        const fileResponse = info.file.response;
        const newFiles = [...fileSetting.files];
        const index = newFileList.findIndex(
          (file) => file.uid == info.file.uid
        );
        newFiles[index] = fileResponse;
        setFileSetting({
          ...fileSetting,
          fileList: newFileList,
          files: newFiles,
        });

        console.log(
          "after file status done ==>",
          newFiles,
          index,
          fileResponse
        );

        if (uploadingFiles.length === 0) {
          if (mediaFormSetting.onChangeSaveFiles) {
            mediaFormSetting.onChangeSaveFiles(filterFilesByStatus(newFiles, "done"));
          }
          resetUploadSettings();
        }

        console.log("File setting ==>", fileSetting);
        // message.success(`${fileResponse.name} file uploaded successfully`);
      } else if (info.file.status === "removed") {
        if(uploadingFiles.length === 0) {
          setMediaFormSetting(prevMediaFormSetting => {
            return {
              ...prevMediaFormSetting,
              okDisabled: false,
            }
          });
        }
      } else if (info.file.status === "error") {
        if (uploadingFiles.length === 0) {
          // Doing this to successfully upload all the other files if upload of last file fails
          if (mediaFormSetting.onChangeSaveFiles) {
            mediaFormSetting.onChangeSaveFiles(filterFilesByStatus(fileSetting.files, "done"));
          }
          resetUploadSettings();
        }

        if (internetStatus == "online" && info.file.response) {
          if (info.file.customMessage) {
            message.error(`${info.file.response}`);
          } else {
            message.error(
              `${info.file.name} file upload failed. Check if file is valid.`
            );
          }
        } else {
          message.error(
            `Not able to upload file, please check your internet connection.`
          );
        }
      }
    },
    // isImageUrl: (file) => {
    //   return file.type.indexOf("image") >= 0;
    // },
    previewFile: async (file) => {
      console.log("Preview file is ==>", file);
      return new Promise((resolve, reject) => {
        const currentFileType = file.type || "";

        if (file.status == "error") {
          // Resolving promise on error too beacause antd component does not handle error only success
          resolve("Preview fail since file type not supported");
        }

        if (
          currentFileType.indexOf("image") >= 0 ||
          currentFileType.indexOf("pdf") >= 0 ||
          currentFileType.indexOf("audio") >= 0 ||
          currentFileType.indexOf("application") >= 0
        ) {
          // console.log(" file url ==> ", URL.createObjectURL(file))
          // resolve(URL.createObjectURL(file))
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            console.log("reader result is ==>", reader.result);
            resolve(reader.result);
          };
        } else {
          // Coming here when uploading a file that is nnot supported
          // Done: User is already seeing a message here

          // reject(null)
          // rejecting with null was causing lots of Sentry errors - replaced with below
          // Resolving promise on error too beacause antd component does not handle error only success
          resolve("Preview fail since file type not supported");
        }
      });
    },
    onPreview: async (file) => {
      const fileResponse = file.response;
      if (fileResponse && fileResponse.status == "done") {
        file = fileResponse;
      }
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      setFileSetting({
        ...fileSetting,
        previewFile: {
          ...file,
          url: src,
        },
      });
    },
    onRemove: (file) => {
      if (notDeletableFiles.find((f) => f.uid === file.uid)) {
        showInfoModal({
          type: "warning",
          title: "This file cannot be deleted now because it is already submitted",
          content: mediaFormSetting.deleteNotAllowedMessage,
        });
        return false;
      } else {
        return new Promise((removeResolve) => {
          showConfirmModal({
            // okButtonProps: { loading: true },
            centered: true,
            title:mrIntl("CommonText.confirm_delete"),
            onOk: (close) => {
              removeResolve(true);
              return new Promise((okResolve) => {
                const newFileList = fileSetting.fileList;
                const fileListIndex = newFileList.indexOf(file);
                newFileList.splice(fileListIndex, 1);

                const newFiles = fileSetting.files;
                const fileResponse = file.response;
                if (fileResponse && fileResponse.id) {
                  const fileIndex = newFiles.findIndex(
                    (f) => f && f.id == fileResponse.id
                  );
                  if (fileIndex >= 0) {
                    newFiles.splice(fileIndex, 1);
                  }
                }

                if (mediaFormSetting.onRemoveFile) {
                  mediaFormSetting.onRemoveFile(
                    fileResponse ? fileResponse : file
                  );
                }
                setFileSetting({
                  ...fileSetting,
                  files: newFiles,
                  fileList: newFileList,
                });
                console.log("File setting on remove ===>", fileSetting);
                okResolve(close);
              });
            },
            mrIntl: mrIntl,
            onCancel: () => {
              removeResolve(false);
            },
          });
        });
      }
    },
    onDragEnd: (result) => {
      setFileSetting({
        ...fileSetting,
        fileList: mediaFormSetting.onDragEnd(result),
      });
    },
    ...mediaFormSetting.selectConfig,
  };

  console.log("Upload Props ==>", uploadProps);

  const saveButtonStyle = {
    margin: "10px",
    float: "right",
  };

  function filterFilesByStatus(files, status) {
    return files.filter((file) => file.status === status);
  }

  const handleOk = () => {
    const fileListLength = fileSetting?.fileList?.length;

    if (mediaFormSetting.uploadOnOk) {
      // If the user clicks the Add button without selecting a file, a message is shown
      if (fileListLength === 0) {
        message.error(mrIntl("CommonText.at_least_one_file_required"));
        return
      }

      setMediaFormSetting({
        ...mediaFormSetting,
        loading: true,
        okDisabled: true,
        selectConfig: {
          ...mediaFormSetting.selectConfig,
          showUploadList: {
            ...mediaFormSetting.selectConfig.showUploadList,
            showRemoveIcon: false
          }
        }
      });

      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          token: token,
        },
      };

      console.log("File settings having file list ==>", fileSetting);

      const url =
        (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments";
      fileSetting.fileList.map((file, index) => {
        if (
          mediaFormSetting.selectConfig &&
          mediaFormSetting.selectConfig.uploadFile
        ) {
          mediaFormSetting.selectConfig.uploadFile(file, {
            extra_data: mediaFormSetting.selectConfig.extra_data,
            successCallback: (response) => {
              const newFiles = [...fileSetting.files];
              newFiles.push(response);
              if (index == fileSetting.fileList.length - 1) {
                if (onSaveMedia) {
                  onSaveMedia(newFiles);
                  setFileSetting({
                    ...fileSetting,
                    fileList: [],
                  });
                }
              } else {
                setFileSetting({
                  ...fileSetting,
                  files: newFiles,
                });
              }
            },
            errorCallback: (error) => {
              console.log("Failed because: " + error);
              setMediaFormSetting({
                ...mediaFormSetting,
                visible: true,
                loading: false,
                okDisabled: false,
              });
              message.error(
                `${file.name} file upload failed. Check if file is valid`
              );
            },
          });
        } else {
          const formData = new FormData();
          formData.append("file", file);

          axios.post(url, formData, config).then(
            (response) => {
              console.log("Returned response ==>", response);
              let responseData = response.data
              const newFiles = fileSetting.files;
              newFiles.push(responseData);

              if (index == fileSetting.fileList.length - 1) {
                if (onSaveMedia) {
                  onSaveMedia(newFiles);
                  setFileSetting({
                    ...fileSetting,
                    fileList: [],
                  });
                }
              } else {
                setFileSetting({
                  ...fileSetting,
                  files: newFiles,
                });
              }

              message.success(`${file.name} file uploaded successfully`);
            },
            (error) => {
              setMediaFormSetting({
                ...mediaFormSetting,
                loading: false,
                okDisabled: false,
              });
              message.error(
                `${file.name} file upload failed. Check if file is valid`
              );
            }
          );
        }
      });
    } else {
      if (onSaveMedia) {
        console.log("file setting ==>", fileSetting);
        // const newFiles = [...fileSetting.fileList].map(item => item.response ? { ...item.response, uid: item.id } : item);
        // onSaveMedia(newFiles);
        onSaveMedia(filterFilesByStatus(fileSetting.files, "done"));

      }
    }
  };

  const handleCancel = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      visible: false,
    });
    if(onCancelMedia) {
      onCancelMedia()
    }
  };

  const getForm = () => {
    let finalRender = [],
      sortableFileList = null,
      uploadComponent = null,
      componentAfterThumbnails;

    const sortable = mediaFormSetting.selectConfig.sortable;

    const shouldShowOnlyCaptureFeature = mediaFormSetting.selectConfig.capture === "environment";

    const fileUploadButtonText = mediaFormSetting.selectConfig.fileUploadButtonText ?? (shouldShowOnlyCaptureFeature ? mrIntl("CommonText.capture") : mrIntl("CommonText.upload"));

    if (sortable) {
      uploadProps = {
        ...uploadProps,
        showUploadList: false,
      };
    }

    if (mediaFormSetting.selectConfig.type === "upload-button") {
      uploadComponent = (
        <>
          <Upload {...uploadProps}>
            {/* To not show upload button when answerMode is fasle for student in auto-mapping view */}
            {!mediaFormSetting.selectConfig.doNotShowUploadButton ? (
              <>
                <Button icon={<UploadOutlined />}>
                  {" "}
                  {fileUploadButtonText}{" "}
                </Button>
                {mediaFormSetting.showSupportedTypes &&
                  mediaFormSetting.selectConfig.listType === "text" && (
                    <p className="file-support-msg">
                      {mrIntl("CommonText.supported_types")}:&nbsp;
                      {mediaFormSetting.supportedFormats}
                    </p>
                  )}
              </>
            ) : null}
          </Upload>
        </>
      );
    } else if (mediaFormSetting.selectConfig.type === "pictures-wall") {
      uploadComponent = (
        <Upload {...uploadProps}>
            {fileSetting.fileList.length >= mediaFormSetting.limit ? null : !mediaFormSetting.selectConfig.hide_uploader && (
              <Tooltip title={mediaFormSetting.selectConfig.disabledUploaderTooltip}>
                {shouldShowOnlyCaptureFeature ? <CameraOutlined /> : <PlusOutlined /> }
                <div style={{ marginTop: 8 }}>{fileUploadButtonText} </div>
              </Tooltip>
            )}
        </Upload>
      );
      componentAfterThumbnails = uploadComponent;
    } else {
      uploadComponent = (
        <Tooltip title={mediaFormSetting.selectConfig.disabled ? mediaFormSetting.selectConfig.disabledUploaderTooltip : null}>
          <span>
            <Dragger
              {...uploadProps}
              style={{ margin: "10px 0px 10px 0px" }}
              className="upload-dragger"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {mrIntl("CommonText.click_or_drag_file_to_this_area_to_upload")}
              </p>
              <p className="ant-upload-hint">
                {mrIntl("SelectFromComputer.supported_types")}: {mediaFormSetting.supportedFormats}
                <br />
                {mrIntl("SelectFromComputer.max_allowed_size")}: {mediaFormSetting.selectConfig.maxSize}
                <br />
                {mediaFormSetting.limit &&
                  `${mrIntl("SelectFromComputer.max_number_of_files")}: ${mediaFormSetting.limit}`}
              </p>
            </Dragger>
          </span>
        </Tooltip>
      );
    }

    if (sortable) {
      if (!componentAfterThumbnails) {
        finalRender.push(uploadComponent);
      }
      finalRender.push(
        <ThumbnailAttachments
          sortable={sortable}
          attachments={fileSetting.fileList}
          onDragEnd={uploadProps.onDragEnd}
          droppableProps={{
            droppableId: "droppable",
            direction: "horizontal",
          }}
          draggableProps={{
            isDragDisabled: false,
          }}
          listProps={{
            showDownloadIcon: false,
            listType: uploadProps.listType,
            onPreview: uploadProps.onPreview,
            onRemove: uploadProps.onRemove,
          }}
          componentAfterThumbnails={componentAfterThumbnails}
        />
      );
    } else {
      finalRender.push(uploadComponent);
    }

    finalRender.push(
      <PreviewAttachment
        type={"modal"}
        visible={fileSetting.previewFile}
        file={fileSetting.previewFile}
        onCancel={() =>
          setFileSetting({
            ...fileSetting,
            previewFile: null,
          })
        }
      />
    );

    return finalRender;
  };

  const getFooter = () => {
    return [
      <Space className="select-from-computer-footer">
{        mediaFormSetting.showExtractQuestionsFromPdfButton && extractQuestionsFromPdf}
        <Button
          key="back"
          onClick={(e) => handleCancel()}
          disabled={mediaFormSetting.okDisabled && mediaFormSetting.loading}
          // style={saveButtonStyle}
        >
          {(mediaFormSetting && mediaFormSetting.cancelText) || "Return"}
        </Button>
        <Button
          key="submit"
          type="primary"
          onClick={(e) => handleOk()}
          loading={mediaFormSetting.okDisabled && mediaFormSetting.loading}
          disabled={mediaFormSetting.okDisabled}
          // style={saveButtonStyle}
        >
          {(mediaFormSetting && mediaFormSetting.okText) || mrIntl("CommonText.submit")}
        </Button>
      </Space>
    ];
  };

  let finalRender;
  if (props.modal) {
    finalRender = (
      <Modal
        {...mediaFormSetting}
        open={mediaFormSetting.visible}
        width={checkMob() ? "" : "50%"}
        centered={checkMob() ? true : false}
        className={checkMob() ? "height-centered-modal" : ""}
        closable={false}
        maskClosable={false}
        keyboard={false}
        title={mediaFormSetting.title || mrIntl("CommonText.insert_media_title")}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={getFooter()}
        loading={false}
      >
        <div className="select-from-computer-form">{getForm()}</div>
      </Modal>
    );
  } else {
    finalRender = (
      <div className="select-from-computer-form">
        {getForm()}
        {mediaFormSetting.requireFooter && getFooter()}
      </div>
    );
  }

  console.log("Final render ==>", finalRender, mediaFormSetting);
  return finalRender;
};
export default SelectFromComputer;
