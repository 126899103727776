import React, { useEffect } from "react";
// import "./GroupItemDetail.scss";
import { Route, useRouteMatch, Link } from "react-router-dom";
import { Tabs, Tag, Tooltip } from "antd";
// import Experiences from "../../Experiences/Experiences";
import GroupMembers from "/src/views/GroupMembers/GroupMembers";
// import { getActiveKeyFromPathname } from "/src/lib/utils/helperMethods";
import { EditOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";

const GroupItemDetail = (props) => {
  const { item, history } = props;
  const { path, url } = useRouteMatch();
  console.log("itemDetail props==>", props);
  const { TabPane } = Tabs;
  let activeKey = "members";
  const mrIntl = useTranslate()
  const currentUser = useSelector(currentUserSelector())
  const groupTeachers = item.group_teachers;

  let groupViewMode = "apClassTeacher"; // cpClassTeacher, apClassStudent

  useEffect(() => {
    history.push(`${url}/${activeKey}`);
  }, []);

  // if(currentUser.role === "student"){
  //   groupViewMode = "apClassStudent";
  // }
  // else{
  //   activeKey = getActiveKeyFromPathname(window.location.pathname) || "tests"
  //   // console.log("activeKey", activeKey)
  // }

  const handleTabClick = (key, event) => {
    // console.log( "itemDetail ontabclick props==>", path, url );
    history.push(`${url}/${key}`);
    activeKey = key;
  };
  let finalRender = null;

  if (groupViewMode === "apClassTeacher") {
    
    const formatTeacherName = ([firstName, lastName]) => lastName ? `${firstName} ${lastName}` : firstName;
    // Get first 3 teachers' names
    const firstThreeTeachers = 
      groupTeachers.slice(0, 3).map(formatTeacherName).join(", ");

    // Add ellipsis if there are more than 3 teachers
    const truncatedTeachersList = 
      groupTeachers.length > 3 
        ? `${firstThreeTeachers}...` 
        : firstThreeTeachers;

    // Get full list of teacher names for tooltip
    const allTeacherNames = 
      groupTeachers.map(formatTeacherName).join(", ");

    // Show tooltip with all names if more than 3 teachers
    const teacherNamesWithTooltip = groupTeachers.length > 3 ? (
      <Tooltip title={allTeacherNames}>
        {truncatedTeachersList}
      </Tooltip>
    ) : (
      truncatedTeachersList
    );

    finalRender = (
      <React.Fragment>
        {/* <PageHeader
        className="site-page-header-responsive class-header"
        // onBack={() => window.history.back()}
        // onBack={() => "/"}
        title={item.name}
        // subTitle={subTitle}
        // extra={operations}
        // extra={[
        //   <Button key="3">Operation</Button>,
        //   <Button key="2">Operation</Button>,
        //   <Button key="1" type="primary">
        //     Primary
        //   </Button>,
        // ]}
        footer={
          <Tabs centered activeKey={activeKey} onTabClick={handleTabClick}>
            <TabPane tab="Tests" key="tests">
              <Route path={`${path}/tests`} render={routeProps => <Experiences params={{by_group_id: item.id}} createUpdateParams={{group_id: item.id}} isRoute={false} {...routeProps}/>}/>
            </TabPane>
            <TabPane tab="Members" key="members">
              List of teachers and students

              <Route path={`${path}/members`} render={routeProps => <GroupMembers params={{by_group_id: item.id}} createUpdateParams={{group_id: item.id}} isRoute={false} {...routeProps}/>}/>
            </TabPane>
            <TabPane tab="Analytics" key="analytics">
              Analytics
            </TabPane>
          </Tabs>
        }
      >
      </PageHeader> */}

        {/* <span>Class show - {JSON.stringify(item)}</span> */}
        <h2>
          <Link to={`/u/classes`}>
            <ArrowLeftOutlined />
            &nbsp;&nbsp;
          </Link>
          <span>{item.name}</span> &nbsp;
          {!item.org_programme_is_mb && !currentUser.org.is_one_roster && !currentUser.org.is_edlink && (
            <Link to={`/u/classes/${item.id}/edit`}>
              <EditOutlined />
              &nbsp;&nbsp;
            </Link>
          )}
          <Tag>{item.org_programme_abbr}</Tag>
          <Tag>{item.subject_label}</Tag>
          <Tag>{item.grade_label}</Tag>
          <Tag>{teacherNamesWithTooltip}</Tag>
        </h2>

        <Tabs activeKey={activeKey} onTabClick={handleTabClick}>
          {/* <TabPane tab="Tests" key="tests">

          <Route path={`${path}/tests`} render={routeProps => <Experiences params={{by_group_id: item.id}} createUpdateParams={{group_id: item.id}} isRoute={false} {...routeProps}/>}/>
          
        </TabPane> */}
          <TabPane tab={mrIntl("GroupItemDetail.members")} key="members">
            {/* List of teachers and students */}

            <Route
              path={`${path}/members`}
              render={(routeProps) => (
                <GroupMembers
                  params={{
                    by_group_id: item.id,
                    by_user_grade_id: item.grade_id,
                    by_org_programme_id: item.org_programme_id,
                    by_org_id: item.org_id,
                  }}
                  createUpdateParams={{ group_id: item.id }}
                  group={item}
                  groupUpdateResource={props.updateResource}
                  isRoute={false}
                  {...routeProps}
                />
              )}
            />
          </TabPane>
          {/* <TabPane tab="Analytics" key="analytics">
          Analytics
        </TabPane> */}
        </Tabs>
      </React.Fragment>
    );
  }
  // else if(groupViewMode === "apClassStudent"){
  //   finalRender = <React.Fragment>
  //     <h3>Student class show - {item.id} - {item.name}</h3>
  //     <hr></hr>

  //     <Route path={`${path}/tests`} render={routeProps => <Experiences params={{by_group_id: item.id}} createUpdateParams={{group_id: item.id}} isRoute={false} {...routeProps}/>}/>

  //   </React.Fragment>
  // }

  return finalRender;
};

GroupItemDetail.defaultProps = {};

GroupItemDetail.propTypes = {};

export default GroupItemDetail;
