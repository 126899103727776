import React, { useState } from "react";
import { Alert, Space, Button, Modal, Carousel, Tag } from "antd";
import { checkMob, getFromLS, setToLS } from "/src/lib/utils/helperMethods";
import { useDispatch, useSelector } from "react-redux";
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import DemoPreHeader from "/src/components/UI/DemoPreHeader/DemoPreHeader";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { isEmpty, toLower, upperFirst } from 'lodash'
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { actions as feedbackActions } from "/src/views/Feedbacks/redux";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CheckInternetConnectivity } from "/src/lib/CheckInternetConnectivity/CheckInternetConnectivity";
import { getInternetStatusSelector } from "/src/views/Experiences/selector";
import { ImMagicWand } from "react-icons/im";
import "./AppNotice.scss";
import { checkUserAppVersionStatus, getAppVersion } from "/src/App/OfflineApp/offlineAppHelper";
import UpdateAppButton from "/src/views/OfflineApps/UpdateAppButton";

const AppNotice = (props) => {
  const isMobile = checkMob();
  const currentUser = useSelector(currentUserSelector());
  const appType = useSelector(appTypeSelector());
  const appRegion = useSelector(appRegionSelector())
  
  const internetStatus = useSelector(getInternetStatusSelector());
  const org = currentUser.org;
  const originalUserRole = localStorage.getItem('originalUserRole');
  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  let finalRender = [];
  let isAlertTypeError = false;
  const appVersion = getAppVersion(appType)
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  let maintenanceAlert = (
    <Alert
      message= {mrIntl("AppNotice.assessprep_maintenance_announcement")}
      banner
      closable={false}
    />
  );
  
  // if (
  //   (import.meta.env.VITE_MODE === "production" ||
  //     import.meta.env.VITE_MODE === "staging" ||
  //     import.meta.env.VITE_MODE === "development") &&
  //   import.meta.env.VITE_REGION === "global" &&
  //   (currentUser.role === "admin" || currentUser.role === "teacher")
  // ) {
  //   finalRender.push(maintenanceAlert);
  // }

  let showNotice = org.custom_fields.show_notice;
  let noticeType = org.custom_fields.notice_type;
  let noticeMessage = org.custom_fields.notice_message;

  let appVersionStatus;
  if (appType !== "web" && appType !== "cros") {
    let deviceInfo = {
      appVersion: appVersion
    }
    appVersionStatus = checkUserAppVersionStatus(appType, deviceInfo, enabledFeatures);
  }

  if (noticeType === "trialEnding") {
    noticeMessage =
      "Your trial is ending soon. Please upgrade to continue using AssessPrep.";
  }
  if (noticeType === "paymentDelay") {
    isAlertTypeError = true
    noticeMessage =
      "Your invoice is due. Please process the invoice immediately to continue using AssessPrep.";
  }
  

  console.log("AppNotice props ", originalUserRole, noticeMessage);


  showNotice = showNotice && !isMobile && currentUser.role === "admin";

  

  if (
    originalUserRole &&
    (originalUserRole === "superadmin" || originalUserRole === "support")
  ) {
    isAlertTypeError = true;
    showNotice = true;
    noticeMessage = `Logged in via ${upperFirst(
      originalUserRole
    )}. Please be careful while making any changes and remember to logout.`;
  }

  let appDownloadButton = <UpdateAppButton />;

  if (!isEmpty(appVersionStatus)
  ) {
    if (appVersionStatus.status === "unsupported") {
      isAlertTypeError = true;
      showNotice = true;
      noticeMessage = <span>You are using an unsupported version of the application. Please update to the latest version immediately.{appDownloadButton}</span>;
    }
    if (appVersionStatus.status === "old") {
      isAlertTypeError = true;
      showNotice = true;
      noticeMessage = <span>You are using an older version of the application. Please update to the latest version. {appDownloadButton} </span>
    }
  }

  // // testing
  // noticeType = "paymentDelay"
  // noticeMessage = "Your invoice is due soon. Please process the invoice before the due date to continue using AssessPrep."
  // showNotice = true
  // // testing

  let noticeAlert = (
    <Alert
      message={noticeMessage}
      // type={(noticeType === "paymentDelay" || originalUserRole) ? "error" : "info"}
      type={isAlertTypeError ? "error" : "info"}
      banner
      showIcon
      closable={false}
      style={{ textAlign: "center" }}
    />
  );

  if (showNotice) {
    finalRender.push(noticeAlert);
  }

  let showDemoPreHeader =
    import.meta.env.VITE_MODE === "demo" ||
    import.meta.env.VITE_MODE === "demo-internal"
  if (showDemoPreHeader) {
    finalRender.push(<DemoPreHeader />);
  }


  let showMBTaskIntegrationAnnouncement = org.is_mb ? true : false
  // let envCheck = import.meta.env.VITE_MODE === "development" || import.meta.env.VITE_MODE === "production" || import.meta.env.VITE_MODE === "staging" // org_ids diff in china and demo
  // let betaOrgIds = [43, 55, 549, 56, 129, 530, 946, 593, 227, 460, 507, 830]
  // let showMBTaskIntegrationAnnouncement = envCheck && betaOrgIds.indexOf(org.id) > -1 ? true : false
  showMBTaskIntegrationAnnouncement = showMBTaskIntegrationAnnouncement && !isMobile && appType === "web" && (currentUser.role === "admin" || currentUser.role === "teacher");

  showMBTaskIntegrationAnnouncement = false // Not required anymore
  
  let MBTaskIntegrationAlertActions = <Space className={"alert-message-actions"}>
    <Button size="small" type={"ghost"} href={"https://managebac.zendesk.com/hc/en-us/articles/4410202672013"} target={"_blank"}>
      Create from MB
    </Button>
    <Button size="small" type={"ghost"} href={"https://managebac.zendesk.com/hc/en-us/articles/4410269026957"} target={"_blank"}>
      Link with MB
    </Button>
    <Button size="small" type={"ghost"} href={"https://managebac.zendesk.com/hc/en-us/articles/4410278998925"} target={"_blank"}>
      Grade in MB 
    </Button>
  </Space>


  let MBTaskIntegrationAlert = (
    <Alert
      // message={"MB task and gradebook integration has been enabled for your school! Give it a go and we'll be happy to hear your thoughts! Here are some guides to help you get started."}
      message={mrIntl("AppNotice.mb_task_and_gradebook_integration")}
      type={"info"}
      banner
      showIcon
      closable={false}
      action={MBTaskIntegrationAlertActions}
      // style={{ textAlign: "center" }}
    />
  );

  if(showMBTaskIntegrationAnnouncement){
    finalRender.push(MBTaskIntegrationAlert);
  }

  const [showAIAssistantInfoModal, setShowAIAssistantInfoModal] = useState(false)
  const [aiAssistantWaitlistJoined, setAIAssistantWaitlistJoined] = useState(false)
  let aiAssistantWaitlistJoinedFromLS = getFromLS("aiAssistantWaitlistJoined")
  const isAIAssistantEnabled = enabledFeatures.ai_generate_questions_with_prompt || import.meta.env.VITE_MODE === "development" ? true : false

  const alertText = isAIAssistantEnabled ? "AI Assistant enabled for your school! Open an assessment and look for the purple Magic button!" : mrIntl("AppNotice.experience_the_future_of_assessments_with_your_own_ai_assistant")

  let aiAssistantLaunchedNotice = <Alert
    message={alertText}
    type={"info"}
    banner
    showIcon
    icon={isAIAssistantEnabled && <ImMagicWand />}
    closable={false}
    action={<Button type={"primary"} shape={"round"} onClick={() => setShowAIAssistantInfoModal(true)}><MrTranslate id={"AppNotice.learn_more"}/></Button>}
    // style={{ textAlign: "center" }}
  />

  let modalTitle = <Space>
    {/* Experience the Future of Assessments with your own AI Assistant!  */}
    {aiAssistantWaitlistJoinedFromLS || aiAssistantWaitlistJoined 
      ? <Tag> <MrTranslate id={"AppNotice.already_in_waitlist"} /> </Tag> 
      : <Button 
          type={"primary"} 
          shape={"round"}
          onClick={() => {
            setToLS("aiAssistantWaitlistJoined", true)
            setAIAssistantWaitlistJoined(true)

            dispatch(
              feedbackActions.create(
                {
                  feedback_type: "feature_request",
                  message: "Interested in AI Assistant.",
                },
                {
                  success: {
                    showMessage: true,
                    message: "Thank you for joining waitlist!",
                  },
                }
              )
            );
        }}><MrTranslate id={"AppNotice.join_waitlist"} /></Button>}
  </Space>
  
  let aiAssistantLaunchedModal = 
    <Modal
      title={isAIAssistantEnabled ? "Let's explore what Assistant can help you with" : modalTitle}
      open={showAIAssistantInfoModal}
      className="ai-assistant-info-modal"
      width={"900px"}
      footer={null}
      // bodyStyle={{height: '300px !important'}}
      onCancel={() => {
        console.log("after close is called ==>")
        setShowAIAssistantInfoModal(false)
      }}
    >
      <Carousel className="ai-assistant-info-carousal" autoplay dots={{className: "ai-assistant-info-carousal-dots"}}>
        <div>
          <h2><b><MrTranslate id={"AppNotice.chat_assistant"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.chat_assistant_msg"} /></h3>
          {/* <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Access to ChatGPT/chatgpt.png"}></img> */}
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/New/Chat assistant.png"}></img>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.assisted_authoring_ai_generated_questions"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.ai_create_assessmet_msg"} /></h3>
          {/* <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Generate/3.png"}></img> */}
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/New/Generate MCQ.png"}></img>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.assisted_authoring_learn_from_any_source"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.assistant_can_analyze_and_generate_questions_from_any_text_or_video_source_msg"} /></h3>
          {/* <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Generate from video/2.png"}></img> */}
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/New/Generate from text 2.png"}></img>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.assisted_authoring_a_never_ending_supply_of_questions"} /></b></h2>
          <h3 >
            <MrTranslate id={"AppNotice.generate_similar_question_msg"} />
          {/* <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Generate similar/1.png"}></img> */}
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/New/Generate similar.png"}></img>
          </h3>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.auto_generate_step_by_step_solutions"} /></b></h2>
          <h3 >
            <MrTranslate id={"AppNotice.utilize_assistant_to_effortlessly_generate_step_by_step_solutions"} />
          {/* <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Generate explanation/1.png"}></img> */}
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/New/Generate explanation.png"}></img>
          </h3>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.grading_revolutionized"} /></b> {isAIAssistantEnabled && <Tag color="processing">Coming soon!</Tag>}</h2>
          <h3><MrTranslate id={"AppNotice.experience_a_whole_new_level_of_efficiency_with_our_ai_powered_grading_system_info_msg"} /></h3>
          {/* <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Grade with AI/5.png"}></img> */}
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/New/Grade with AI.png"}></img>
        </div>
        {/* <div>
          <h2><b><MrTranslate id={"AppNotice.ai_detection_and_plagiarism_check"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.promote_authenticity_and_accountability_with_assesspreps"} /></h3>
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/AI detection/1.png"}></img>
        </div> */}
        
      </Carousel>
      {/* <Carousel className="ai-assistant-info-carousal" autoplay dots={{className: "ai-assistant-info-carousal-dots"}}>
        <div>
          <h2><b><MrTranslate id={"AppNotice.assisted_authoring_ai_generated_questions"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.ai_create_assessmet_msg"} /></h3>
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Generate/3.png"}></img>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.assisted_authoring_learn_from_any_source"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.assistant_can_analyze_and_generate_questions_from_any_text_or_video_source_msg"} /></h3>
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Generate from video/2.png"}></img>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.assisted_authoring_a_never_ending_supply_of_questions"} /></b></h2>
          <h3 >
            <MrTranslate id={"AppNotice.generate_similar_question_msg"} />
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Generate similar/1.png"}></img>
          </h3>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.auto_generate_step_by_step_solutions"} /></b></h2>
          <h3 >
            <MrTranslate id={"AppNotice.utilize_assistant_to_effortlessly_generate_step_by_step_solutions"} />
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Generate explanation/1.png"}></img>
          </h3>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.grading_revolutionized"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.experience_a_whole_new_level_of_efficiency_with_our_ai_powered_grading_system_info_msg"} /></h3>
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Grade with AI/5.png"}></img>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.ai_detection_and_plagiarism_check"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.promote_authenticity_and_accountability_with_assesspreps"} /></h3>
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/AI detection/1.png"}></img>
        </div>
        <div>
          <h2><b><MrTranslate id={"AppNotice.controlled_access_to_chatgpt"} /></b></h2>
          <h3><MrTranslate id={"AppNotice.promote_ethical_ai_info_msg"} /></h3>
          <img src={"https://v2res1.assessprep.com/manual_uploads/new_features_images/ai_assistant/AP AI screenshots/Access to ChatGPT/chatgpt.png"}></img>
        </div>
      </Carousel> */}
    </Modal>

  if (currentUser.role == "admin" || currentUser.role == "teacher") {
    finalRender.push(aiAssistantLaunchedNotice)
    finalRender.push(aiAssistantLaunchedModal)
  }


  finalRender.push(<CheckInternetConnectivity />);
  let offlineAlert;
  if (internetStatus !== "online") {
    offlineAlert = (
      <Alert
        message={mrIntl("ExperienceLayout.it_seems_you_are_not_connected_to_the_internet")}
        banner
        closable={false}
        // className={"internet-alert"}
        // action={shouldAddActionBtn && <HowToFixBtn />}
      />
    );
  }
  finalRender.push(offlineAlert);


  return <div className={isAIAssistantEnabled ? "app-notice-container ai-assistant-enabled" : "app-notice-container"}>{finalRender}</div>;
};

export default AppNotice;
