import React from "react";
import MagicButton from "../../Segments/GenerateAI/MagicButton";
import { useDispatch } from "react-redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { message } from "/src/components/UI/AntdAppHelper";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { LoadingOutlined } from "@ant-design/icons";
import { isGradingWithAIInProgress } from "/src/lib/utils/helperMethods";

const GradeSubmission = ({ item }) => {
  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  const isGradingWithAIInProgressForEU = isGradingWithAIInProgress(item);
  // Keep the stop button active when an assessment is open in two tabs:
  // If one tab publishes a submission and the other starts grading and refreshes,
  // the '!isGradingWithAIInProgressForEU' condition ensures the stop button remains enabled during grading.
  const disabled = item.marked && !isGradingWithAIInProgressForEU
  
  const handleGradeSubmission = () => {
    const gradingOptions = {
      eu_id: item.id,
    };

    dispatch(
      experienceUserActions.gradeSubmissionsWithAi(gradingOptions, {
        successCallback: (data) => {
          console.log("gradeSubmisison data ===>", data);
          const finalExperienceUser = formatJsonApiData(
            data.experience_user
          );
          const updatedExperienceUser = {
            ...item,
            ...finalExperienceUser,
          };
          dispatch(
            experienceUserActions.showSuccess(
              { data: { experience_user: updatedExperienceUser } },
              { success: { showMessage: false } }
            )
          );
        },
        errorCallback: (error) => {
          message.error(
            mrIntl("CommonText.something_went_wrong_please_try_again")
          );
          console.log("gradeAllWithAI errorCallback", error);
        },
      })
    );
  };

  const cancelGradeSubmission = () => {
    const url = `${import.meta.env.VITE_API_URL}cancel_grade_submission_with_ai`;
    const params = {
      eu_id: item.id,
    };
    axiosInstance.instance.post(url, params)
      .then(response => {
        dispatch(
          experienceUserActions.fetch(
            {
              params: {
                by_ids: [item.id],
                with_all_eus_user_responses: true,
              },
            },
            {
              mergeFetchedData: true,
            }
          )
        );
      })
      .catch(error => {
        console.error('Failed to cancel grading submission:', error);
        message.error(mrIntl("CommonText.something_went_wrong_please_try_again"));
      });
  };

  return (
    <MagicButton
      disabled={disabled}
      onClick={isGradingWithAIInProgressForEU ? cancelGradeSubmission : handleGradeSubmission}
      shape="default"
      type="text"
      text={isGradingWithAIInProgressForEU ? "Stop grading" : "Grade Submission"}
      mode="subtle"
      size="small"
      icon={isGradingWithAIInProgressForEU ? <LoadingOutlined /> : null}
      feature_code="grade_submission_with_ai"
      ghost={true}
      tooltipTitle={disabled ? mrIntl("GradeSubmission.grading_is_disabled_for_published_submissions") : ""}
    />
  );
};

export default GradeSubmission;
