import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appTypeSelector } from "./offlineAppSelectors";
import { appRegionSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { dispatchToUpdateNativeMacApp } from "/src/views/OfflineApps/UpdateAppButton";

export const CheckNativeAppUpdate = (props) => {
  const dispatch = useDispatch();
  const { handleSetAutoUpdateChecking } = props;
  const appType = useSelector(appTypeSelector());
  const appRegion = useSelector(appRegionSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector())

  useEffect(() => {
    // Calling getAppVersion function here becuase of first time we are getting the appType to web
    // We have initial appType value is "" instead of "web" check login -> redux for more description
    if (appType !== "" && appType !== "mac") {
      handleSetAutoUpdateChecking?.(false);
      return;
    }
  
    window.addEventListener("SetAutoUpdateStatus", handleSetAutoUpdateStatus);

    const callbackForAutoUpdateFailOrCompletion = () => {
      handleSetAutoUpdateChecking?.(false);
    }
   
    dispatchToUpdateNativeMacApp(
      "on_mount",
      appType, 
      appRegion, 
      enabledFeatures, 
      dispatch,
      callbackForAutoUpdateFailOrCompletion
    );

    return () => {
      window.removeEventListener(
        "SetAutoUpdateStatus",
        handleSetAutoUpdateStatus
      );
    };
  }, [appType]);

  
  const handleSetAutoUpdateStatus = (e) => {
    // Check if the update status indicates we should stop checking for updates
    const updateCompletionStatuses = [
      "skip", 
      "error",
      "complete",
      "failedToDownload",
      "alreadyCheckedTheUpdates"
    ];

    // e.detail contains the auto update status
    if (updateCompletionStatuses.includes(e.detail)) {
      handleSetAutoUpdateChecking?.(false);
    }
  };

  return <></>;
};
