import React, { useState } from "react";
import { Modal, Space, Button, Progress } from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { usePollingEffect } from "/src/views/Segments/InteractiveHelpers";
import { activeAdjustedExperienceSelector } from "../../selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TranslationProgressModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const experience = useSelector(activeAdjustedExperienceSelector());
  const mrIntl = useTranslate();

  const defaultTranslationProgress = experience.custom_fields?.translation_status;

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [translationProgress, setTranslationProgress] = useState(defaultTranslationProgress)

  const translationStatus = translationProgress?.status

  const isTranslationFailed = translationStatus === "failed";   
  const isTranslationPartiallyCompleted = translationStatus === "partially_completed";
  const isTranslationCompleted = translationStatus === "completed";
  const isTranslationFailedOrPartiallyCompleted = isTranslationFailed || isTranslationPartiallyCompleted;

  const totalSegments = Object.values(experience.custom_fields?.segment_types_count || {}).reduce(
    (acc, count) => acc + count,
    0
  );

  const translationPercentage = translationProgress
    ? parseInt((translationProgress.translated_segments / totalSegments) * 100)
    : 0;

  let shouldPoll =
    translationProgress &&
    !(isTranslationCompleted || isTranslationFailedOrPartiallyCompleted);

  const updateExperience = () => {
    dispatch(
      experienceActions.show({ id: experience.uid }, { forceApiRequest: true })
    );
  };

  const checkTranslationProgress = (clearIntervalCallback) => {
    const handleSuccess = (data) => {
      console.log("hadnelSuccess data ===>", data);
      
      const { status } = data.translation_status;
      const progressCompleted = status === "completed" || status.includes("failed");

      setTranslationProgress(data.translation_status);

      if (progressCompleted) {
        if (status === "completed") {
          updateExperience();
        }
        clearIntervalCallback();
      }
    };

    const handleError = (error) => {
      console.error("Translation status check failed", error);
      // message.error("Something went wrong. Please go back to the original assessment and attempt the translation again.");
    };

    dispatch(
      experienceActions.translateAssessmentStatus(
        { experience_id: experience.id },
        {
          successCallback: handleSuccess,
          errorCallback: handleError,
        }
      )
    );
  };

  usePollingEffect(shouldPoll, checkTranslationProgress, 10000);

  useState(() => {
    if (shouldPoll) {
      setIsModalOpen(true);
    }
  }, [shouldPoll]);


  return (
    <Modal
      visible={isModalOpen}
      closable={false}
      footer={null}
      className="translation-progress-modal"
    >
      <Space direction="vertical">
      {/* Icon */}
      {isTranslationFailedOrPartiallyCompleted ? (
          <ExclamationCircleTwoTone className="translation-modal-fail-icon" />
        ) : (
          <Progress
            type="circle"
            percent={translationPercentage}
            size={80}
            status={isTranslationCompleted ? "success" : "normal"}
          />
        )}
        {/* Progress Text */}
        <p className="translation-progress-text">
          {isTranslationFailed
            ? mrIntl("ExperienceManage.translation_failed")
            : isTranslationPartiallyCompleted 
              ? <>
                {mrIntl("TranslationProgressModal.translation_partially_completed")}
                {translationProgress.message ? (
                  <p className="translation-progress-subtext">
                    ({translationProgress.message})
                  </p>
                ) : null}
              </>
              : isTranslationCompleted 
                ? mrIntl("TranslationProgressModal.translation_completed") 
                : mrIntl("ExperienceManage.translation_in_progress")}
        </p>
        {/* Buttons */}
        <Space className="translation-progress-buttons">
          {isTranslationFailedOrPartiallyCompleted ? (
            <Button
              type="default"
              size="large"
              className="translation-progress-button"
              onClick={() => updateExperience()}
            >
              {mrIntl("CommonText.close")}
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                size="large"
                className="translation-progress-button"
                onClick={() => history.push("/u/tests")}
              >
                {mrIntl("ExperienceManage.go_to_my_assessments")}
              </Button>
              <Button
                type="default"
                size="large"
                className="translation-progress-button"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
            </>
          )}
        </Space>
      </Space>
    </Modal>
  );
};

export default TranslationProgressModal;
