import * as Sentry from "@sentry/react";
import { checkIPAD, checkMob, getBrowser, getBrowserName, getBrowserVersion, getMobileOperatingSystem, getOS, getRegexExpFor } from "/src/lib/utils/helperMethods";
import { toLower } from "lodash";
// import { getAppVersion, getDeviceInfo, getLatestAppVersion, isNewAppVersion } from "/src/lib/utils/helperMethods";

//Now not using due to using the native SDK (Mac app v11.0.0) , but future use reference
export const sendNativeCaptureMessageToSentry = (appType,errorTitle,errorMsg) => {
  const currentSystemAppVersion = getAppVersion(appType);
  Sentry.captureMessage(
    `${errorTitle}: ${currentSystemAppVersion}\n${errorMsg}`
  );
};

export const checkNativeMethodSupported = (appType, methodName) => {
  let methodSupported = false;
  const currentAppVersion = getAppVersion(appType);

  if (methodName === "getSecurityStatus" || methodName === "showQuitAlert") { 
    if (appType === "mac" && isNewAppVersion(currentAppVersion, "10.2.0")) {
      methodSupported = true;
    }

    if (appType === "ios" && isNewAppVersion(currentAppVersion, "3.2.0")) {
      methodSupported = true;
    }
  } else if (methodName === "openChildWindow" || methodName === "openNewWindow") {
    if (appType === "mac" && isNewAppVersion(currentAppVersion, "10.1.0")) {
      methodSupported = true;
    }
  } else if (methodName === "openContentInModal") {
    if (appType === "mac" && isNewAppVersion(currentAppVersion, "11.0.0")) {
      methodSupported = true;
    } 
  } else if (methodName === "exitApp") {
    if (appType === "ios" && isNewAppVersion(currentAppVersion, "3.2.0")) {
      methodSupported = true;
    }
  }

  return methodSupported;
};
export const getOfflineAppDownloadUrl = (appType, region, enabledFeatures = {}, returnAAC=false) => {
  let finalUrl;
  switch (appType) {
    case "mac":
      if (region === "china") {
        // "https://production-eks-assets.assessprep.cn/AP_Mac/AssessPrep_Mac_v8.0.0_2022_01_25.dmg";
        // "https://production-eks-assets.assessprep.cn/AP_Mac/AssessPrep_Mac_v9.0.1_2023_01_12.dmg";
        //"https://production-eks-assets.assessprep.cn/AP_Mac/AssessPrep_Mac_v10.1.0_2023_07_21.dmg";
        // finalUrl =
        //   enabledFeatures.release_mac_app_v_12_0_0
        //     ? "https://production-eks-assets.assessprep.cn/AP_Mac/AssessPrep_Mac_v12.0.0_2025_02_24.dmg"
        //     : "https://production-eks-assets.assessprep.cn/AP_Mac/AssessPrep_Mac_v10.2.0_2024_01_04.dmg";
        finalUrl =
          enabledFeatures.release_mac_app_v_12_1_0
            ? "https://production-eks-assets.assessprep.cn/AP_Mac/AssessPrep_Mac_v12.1.0_2025_04_04.dmg"
            : enabledFeatures.release_mac_app_v_11_0_0
              ? "https://production-eks-assets.assessprep.cn/AP_Mac/AssessPrep_Mac_v11.0.0_2024_11_17.dmg"
              : "https://production-eks-assets.assessprep.cn/AP_Mac/AssessPrep_Mac_v10.2.0_2024_01_04.dmg";
      } else {
        // v8.0.0
        // finalUrl = "https://tinyurl.com/2p8ckn7h";
        // v9.0.1
        // finalUrl = "https://d15evq3hcqea3u.cloudfront.net/AP+Mac/AssessPrep%2BMac%2Bv9.0.1_2023_01_12.dmg";
        // v9.0.2
        //v10.1.0
        //finalUrl = "https://d15evq3hcqea3u.cloudfront.net/AP+Mac/AssessPrep%2BMac%2Bv10.1.0_2023_07_21.dmg";
        if (enabledFeatures.release_mac_app_v_12_1_0) {
          finalUrl = "https://d15evq3hcqea3u.cloudfront.net/AP+Mac/AssessPrep_Mac_v12.1.0_2025_04_04.dmg"
        } else if (enabledFeatures.release_mac_app_v_11_0_0) {
          finalUrl = "https://d15evq3hcqea3u.cloudfront.net/AP+Mac/AssessPrep_Mac_v11.0.0_2024_11_17.dmg";
        } 
        ////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////
        // IMPORTANT: For legacy tab passing enabledFeatures = {legacy_ap_mac_9_0_2_allowed: true} which is just alias, value is never saved in enabledFeatures  
        else if (enabledFeatures.legacy_ap_mac_9_0_2_allowed) { 
          finalUrl = "https://d15evq3hcqea3u.cloudfront.net/AP+Mac/AssessPrep%2BMac%2Bv9.0.2_2023_04_15.dmg";
        ////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////
        } else {
          finalUrl = "https://d15evq3hcqea3u.cloudfront.net/AP+Mac/AssessPrep_Mac_v10.2.0_2024_01_04.dmg";
        }
      }
      break;
    case "win8_1": 
      if (region === "china") {
        finalUrl =
          // "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.4.0.480_SetupBundle.exe";
        // finalUrl =
        //   "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.4.1.505_SetupBundle.exe";
        finalUrl =
        "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.5.0.544_SetupBundle.exe";
      } else {
        // finalUrl = "https://tinyurl.com/48963uvk";
        // finalUrl = "https://tinyurl.com/2p9hr7sc";
        finalUrl = "https://tinyurl.com/2s4krphv"; // 3.5.0
        // finalUrl = "https://v2res1.assessprep.com/SEB_setup_files/SEB_3.4.1.505_SetupBundle.exe";
      }
      break;
    case "win10":
      if (region === "china") {
        finalUrl =
          // "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.4.0.480_SetupBundle.exe";
        // finalUrl =
        //   "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.4.1.505_SetupBundle.exe";
        finalUrl =
        "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.8.0.742_SetupBundle.exe";
      } else {
        // finalUrl = "https://tinyurl.com/48963uvk";
        // finalUrl = "https://tinyurl.com/2p9hr7sc";
        finalUrl = enabledFeatures.release_seb_app_v_3_8_0_9999 ? "https://tinyurl.com/bdh79339" : "https://tinyurl.com/mryf9yt3" // 3.8.0
        // finalUrl = "https://v2res1.assessprep.com/SEB_setup_files/SEB_3.4.1.505_SetupBundle.exe";
      }
      break;
    case "ios":
      finalUrl = "https://apps.apple.com/us/app/id1543293245";
      break;
    default:
      finalUrl = ""
      break;
  }
  return finalUrl;
};
export const getAppNativeVars = (appType) => {
  // TODO: should only return appNativeVars and not 598
  const userAgent = navigator.userAgent || window.navigator.userAgent;
  let appNativeVars = {}
  if (appType === "seb") {
    appNativeVars.appVersion = userAgent.match(/SEB\/([^\s]+)/g) && userAgent.match(/SEB\/([^\s]+)/g)[0].replace(/SEB\//g, '');
  }
  if (appType === "mac" || appType === "ios") {
    let localAppNativeVars = localStorage.getItem("apNativeVars");
    if (localAppNativeVars && localAppNativeVars !== undefined && localAppNativeVars !== "undefined") {
      appNativeVars = JSON.parse(localAppNativeVars);
    }
  }
  return appNativeVars;
};
export const getAppVersion = (appType) => {
  const userAgent = navigator.userAgent || window.navigator.userAgent;
  let appVersion = '';
  if (appType === "seb") {
    appVersion = userAgent.match(/SEB\/([^\s]+)/g) && userAgent.match(/SEB\/([^\s]+)/g)[0].replace(/SEB\//g, '');
  }
  if (appType === "mac" || appType === "ios") {
    let appNativeVars = getAppNativeVars(appType)
    appVersion = appNativeVars.appVersion
  }
  return appVersion;
  // return "10.2.0";
};
export const supportsMacAACSecurity = (appType) => {
  let supportsAAC = false
  if (appType === "ios") {
    supportsAAC = true;
  } else if(appType === "mac"){
    const deviceInfo = getDeviceInfo(appType);
    // const currentAppVersion = "10.0.0"
    // const currentAppVersion = "9.0.2"
    // const deviceOsVersion = "12.5"; // done: issue here when comparing 12.5 to 10.15.4 in isNewAppVersion
    const currentAppVersion = getAppVersion(appType)
    const deviceOsVersion = deviceInfo.osVersion;
    if (isNewAppVersion(currentAppVersion, "10.0.0") && isNewAppVersion(deviceOsVersion, "10.15.4")){ 
      supportsAAC = true
    }
  }
  return supportsAAC
}
export const getOSVersion = (appType, systemInfo, finalBrowserVersion = '') => {
  let finalVersion = "";
  // @nitin why Mac OS also here? can remove?
  if (appType === "Mac OS" || appType === "mac" || appType === "ios") {
    if (systemInfo) {
      let version =
        systemInfo.match(/Version \d.+ \(B/gi) &&
        systemInfo.match(/Version \d.+ \(B/gi)[0];
      if (version) {
        finalVersion = version.replace(/Version | \(B/gi, "");
      }
    }
  }
  console.log("getOSVersion appType", appType);
  if(appType === "cros"){
    // In case of ChromeOS, OS version = Chrome browser version
    finalVersion = finalBrowserVersion
  }
  return finalVersion;
};
export const isNewAppVersion = (appVersion,compareVersion) =>{
  // desired output: appVersion >= compareVersion ? true : false
  if(!appVersion || !compareVersion){
    return false
  }
  
  const appVersionComponents = appVersion.split('.');
  const compareVersionComponents = compareVersion.split('.');

  // Compare the components in turn
  for (let i = 0; i < appVersionComponents.length; i++) {
    const appVersionComponent = parseInt(appVersionComponents[i] || 0); // default to 0 if there are no more components in appVersion
    const compareVersionComponent = parseInt(compareVersionComponents[i] || 0); // default to 0 if there are no more components in compareVersionVersion
    
    if (appVersionComponent > compareVersionComponent) {
      return true;
    } else if (appVersionComponent < compareVersionComponent) {
      return false;
    }
  }
  
  // If all components are equal, the versions are the same
  return true;

}
export const getDeviceInfo = (appType = '') => {
  console.log("calling getDeviceInfo");
  // var browserName = getBrowser().match(/\w.+\//g) && getBrowser().match(/\w.+\//g)[0];

  var finalBrowserName = getBrowserName();
  var browserVersion;
  if (getBrowser().includes('Safari')) {
    browserVersion = getBrowserVersion(getBrowser(), /\/\d+([^\s]+)/g, null, 0) 
    // getBrowser().match(/\/\d+([^\s]+)/g) && getBrowser().match(/\/\d+([^\s]+)/g)[0];
  } else {
    browserVersion = getBrowserVersion(getBrowser(), /\/\d+/g, null, 0)
    // getBrowser().match(/\/\d+/g) && getBrowser().match(/\/\d+/g)[0];
  }


  var finalBrowserVersion = browserVersion ? browserVersion.replace(/\//g, "") : "";
  console.log('inside get browser==>', finalBrowserVersion);

  let appNativeVars = getAppNativeVars(appType)
  let osVersion = getOSVersion(appType, appNativeVars.systemInfo, finalBrowserVersion);
  let appVersion = getAppVersion(appType)
  // const deviceInfo = checkMob() ? getMobileOperatingSystem() : getOS() + " " + finalBrowserName + "-" + finalBrowserVersion;
  const deviceInfo = {
    os: (checkMob() || checkIPAD()) ? getMobileOperatingSystem() : getOS(),
    browser: finalBrowserName + "-" + finalBrowserVersion,
    osVersion: osVersion,
    appVersion: appVersion,
    app_type: appType,
    ...appNativeVars
  }
  return deviceInfo;
}

export const MAC_AUTO_UPDATE_FEED_BASE_URL = "https://d15evq3hcqea3u.cloudfront.net/manual-uploads/AP+Mac"; 

// Handle case for china auto update feed url
// export const MAC_AUTO_UPDATE_FEED_BASE_URL_CHINA = "https://production-eks-assets.assessprep.cn/manual-uploads/AP+Mac";

// IMP: Feature flags must be in descending version order (newest to oldest) to ensure we match the latest supported version first
const macAppReleaseFlags = [
  // "release_mac_app_v_12_3_0",
  // "release_mac_app_v_12_2_0",
  "release_mac_app_v_12_1_0", 
  "release_mac_app_v_12_0_0",
  "release_mac_app_v_11_0_0"
]

const appVersionsList = {
  seb: [
    { version: "3.8.0.9999", status: "latest" },
    { version: "3.8.0.999", status: "old" },
    { version: "3.8.0", status: "old" },
    { version: "3.5.0", status: "old" },
    { version: "3.4.1", status: "unsupported" },
    { version: "3.4.0", status: "unsupported" },
    { version: "3.3.2", status: "unsupported" },
    { version: "3.3.1", status: "unsupported" },
    { version: "3.3.0", status: "unsupported" },
    { version: "3.2.0", status: "unsupported" },
    { version: "3.1.1", status: "unsupported" },
    { version: "3.1.0", status: "unsupported" },
    { version: "3.0.1", status: "unsupported" },
    { version: "3.0.0", status: "unsupported" },
    { version: "2.4.1", status: "unsupported" },
  ],
  mac: [
    // {
    //   version: "12.3.0",
    //   status: "latest",
    //   feedUrl: `${MAC_AUTO_UPDATE_FEED_BASE_URL}/appcast_assessprep_mac_app_12_3_0_update_staging.xml`,
    //   versionFlag: "release_mac_app_v_12_3_0",
    // }, // TODO : remove when merge to prod, now add due to check auto update feature
    // {
    //   version: "12.2.0",
    //   status: "latest",
    //   feedUrl: `${MAC_AUTO_UPDATE_FEED_BASE_URL}/appcast_assessprep_mac_app_12_2_0_update.xml`,
    //   versionFlag: "release_mac_app_v_12_2_0",
    // }, // TODO : remove when merge to prod, now add due to check auto update feature
    {
      version: "12.1.0",
      status: "latest",
      // feedUrl: `${MAC_AUTO_UPDATE_FEED_BASE_URL}/appcast_assessprep_mac_app_12_1_0_update.xml`,
      feedUrl: null,
      versionFlag: "release_mac_app_v_12_1_0",
    }, // TODO : remove when merge to prod, now add due to check auto update feature
    {
      version: "12.0.0",
      status: "old",
      feedUrl: null,
      versionFlag: "release_mac_app_v_12_0_0",
    },
    {
      version: "11.0.0",
      status: "latest",
      feedUrl: null,
      versionFlag: "release_mac_app_v_11_0_0",
    },
    { 
      version: "10.2.0", 
      status: "latest",
      feedUrl: null,
      versionFlag: "release_mac_app_v_10_2_0",
    },
    { version: "10.1.0", status: "unsupported" }, //AAC mode app
    { version: "10.0.0", status: "unsupported" }, //IMP: v10.0.0 released for only few schools
    { version: "9.0.2", status: "unsupported" }, //Major running app (without ACC mode) before release v10.1.0
    { version: "9.0.1", status: "unsupported" },
    { version: "9.0.0", status: "unsupported" },
    { version: "8.0.1", status: "unsupported" },
    { version: "8.0.0", status: "unsupported" },
    { version: "7.0.1", status: "unsupported" },
    { version: "7.0.0", status: "unsupported" },
  ],
  ios: [
    { version: "3.2.0", status: "latest" },
    { version: "2.0.5", status: "old" },
    { version: "1.0.2", status: "old" },
    { version: "1.0.1", status: "unsupported" },
    { version: "1.0.0", status: "unsupported" },
  ],
  cros: [{ version: "107", status: "latest" }],
  web: {
    chrome: [{ version: 84, status: "unsupported" }],
    firefox: [{ version: 81, status: "unsupported" }],
    safari: [{ version: 14, status: "unsupported" }],
  },
};

export const getAppVersionInfo = (appType, options) => {
  const { key, value, browserName } = options;
  const data = appVersionsList;
  if (!value || !appType) {
    return {};
  }
  if (appType === "web") {
    if (!browserName || !data[appType][browserName]) {
      return {};
    }
    return data[appType][browserName].find((item) => item[key] === value);
  }
  return data[appType] ? data[appType].find((item) => item[key] === value) : {};
};

export const browserUnsupported = (appType, deviceInfo) => {
  if (appType !== "web") return false;

  const browserName = getBrowserName()?.toLowerCase();
  const browserVersion = getBrowserVersion(deviceInfo.browser, /\d.+/g, null, 0);
  const browserData = appVersionsList.web?.[browserName]?.[0];

  return browserData?.status === 'unsupported' && browserVersion <= browserData.version;
};

export const getLatestAppVersion = (
  appType,
  enabledFeatures = {},
  returnAAC = false
) => {
  let latestVersion = appVersionsList[appType] ? appVersionsList[appType][0]["version"] : null;

  // We implemented a feature flag for 10.2.0 app that its app was accessible/download to only a limited number of schools and now publish for all
  // if (appVersionsList[appType] && appType === "mac") {
  //   latestVersion = appVersionsList[appType][1]["version"];
  // }

  return latestVersion
};

export const macAppsToClose = [
  "com.wiheads.paste",
  "com.wiheads.paste-setapp",
  "com.sublimetext.3",
  "com.microsoft.Word",
  "com.microsoft.Powerpoint",
  "com.microsoft.Excel",
  "com.microsoft.Outlook",
  "com.apple.mail",
  "com.fiplab.clipboard",//free copy clip app
  "com.fiplab.copyclip2",//version of 2 copy clip paid app
  "net.maxbor.CopyLess",//free copy clip app
  "com.goodsnooze.MenuGPT",//mac gpt app
  "com.apple.shortcuts", // default shortcut app , its not work/app close now
  "com.apple.shortcuts.remote.help", // shortcut app , its not work/app close now
  "com.kittenyang.ios.ChatGPT-Mac-MenuBar",
  "com.vincelwt.chatgptmac",
  "com.Ai-writer.gpt3",
  "com.app.menubarx",
  "me.tanmay.AnyGPT",
  "com.smileonmymac.textexpander",//text expanders app, apple default text replacement didn't work
  "com.oslash.macapp",
  "com.bartelsmedia.PhraseExpressOSX",
  "com.federicoterzi.espanso",
  "com.typeit4me.TypeIt4MeMenu",
  "com.trankynam.aText",
  "com.macility.typinator2",
  "com.typedesk.app",
  "com.stairways.keyboardmaestro.editor",
  "com.runningwithcrayons.Alfred",
  "com.openai.chat",
];

export const checkIfSEBHasDynamicConfig = (appType) => {
  let hasDynamicConfig = false
  if (appType === "seb") {
    let userAgent = window.navigator.userAgent;
    if (userAgent && userAgent.indexOf("Dynamic_AssessPrepSEB_HDC5hjdqmjypb3qxn") > -1) {
      hasDynamicConfig = true
    } 
  }
  return hasDynamicConfig
}
export const supportsDynamicConfig = (appType) => {
  let dynamicConfigSupported = false;
  const deviceInfo = getDeviceInfo(appType);
  const currentAppVersion = getAppVersion(appType);
  const deviceOsVersion = deviceInfo.osVersion;

  if (appType === "ios" && isNewAppVersion(currentAppVersion, "3.2.0")) {
    return true;
  }

  if (appType === "mac") {
    if (
      isNewAppVersion(currentAppVersion, "10.2.0") &&
      isNewAppVersion(deviceOsVersion, "10.15.4")
    ) {
      dynamicConfigSupported = true;
    }
  }
  return dynamicConfigSupported;
}
export const checkUserAppVersionStatus = (appType, deviceInfo, enabledFeatures = {}) => {
  let appVersionStatus = {};
  if (deviceInfo) {
    if (appType === "web" || appType === "cros") {
      let browserVersion = getBrowserVersion(deviceInfo.browser, /\d.+/g, null, 0);
      let browserName = toLower(getBrowserName(deviceInfo.browser));
      appVersionStatus = getAppVersionInfo(appType, { key: "version", value: browserVersion, browserName });
    } else {
      let getLatestVersionOfApp = getLatestAppVersion(appType, enabledFeatures);
      appVersionStatus = getAppVersionInfo(appType, { key: "version", value: deviceInfo.appVersion });
      if (appType === "mac" && getLatestVersionOfApp == deviceInfo.appVersion) {
        //Checking because mac app latest version according to feature flag
        appVersionStatus = { ...appVersionStatus, status: "latest" };
      }
    }
  }
  return appVersionStatus;
};

export const checkAutoUpdateSupported = (appType, enabledFeatures = {}) => {
  let autoUpdateSupported = false;
  const deviceInfo = getDeviceInfo(appType);
  const currentAppVersion = getAppVersion(appType);
  const deviceOsVersion = deviceInfo.osVersion;
  //const latestAppVersion = getLatestAppVersion(appType) // TODO: remove after QA

  const macAutoUpdateFeedInfo = getMacAutoUpdateFeedInfo(appType, enabledFeatures)
  console.log("autoUpdateSupported======>>>460",macAutoUpdateFeedInfo);
  if (!macAutoUpdateFeedInfo) return false;
  const enabledAppVersion = macAutoUpdateFeedInfo.version

  if (appType === "mac") {
    if (
      //Auto App supported from v12.1.0
      //Output is currentAppVeresion >= 12.1.0 && not the latest version
      (isNewAppVersion(currentAppVersion, "12.1.0") && !isNewAppVersion(currentAppVersion, enabledAppVersion)) &&
      isNewAppVersion(deviceOsVersion, "10.15.4")
    ) {
      autoUpdateSupported = true;
    }
  }
  console.log("autoUpdateSupported======>>>",autoUpdateSupported);
  return autoUpdateSupported;
};

export const getAutoUpdateType = (triggerType, appType, enabledFeatures = {}) => {
  // Only handle Mac app type
  if (appType !== "mac") {
    return "normal";
  }

  // For automatic updates triggered on mount
  if (triggerType === "on_mount") {
    // Return "force" if force updates enabled, otherwise "normal"
    return enabledFeatures.allow_mac_app_force_update 
      ? "force" 
      : "normal";
  }

  // For manual updates triggered by user click
  if (triggerType === "on_click") {
    return "manual";
  }

  // Default to normal update type
  return "normal";
};

export const getMacAutoUpdateFeedInfo = (appType, enabledFeatures = {}) => {
  // Return null if not a Mac app type
  if (appType !== "mac") return null;
  
  // Get list of Mac app versions, return null if none found
  const appVersions = appVersionsList[appType];
  if (!appVersions) return null;

  // We want to auto update to the latest app version which is enabled in the feature flags
  // IMP: Find first enabled feature flag (sorted in descending order in macAppReleaseFlags array) that has a matching app version
  // enabledFeatures contains flags that are turned on
  // allowedAppVersionFlag checks if flag matches an app version

  let appVersionInfo = null;
  // Example:
  // macAppReleaseFlags = ['mac_app_v12_1_0', 'mac_app_v12_0_0']
  // enabledFeatures = {mac_app_v12_1_0: true, mac_app_v12_0_0: true}
  // First iteration: flag='mac_app_v12_1_0' -> appVersionInfo={version: '12.1.0', feedUrl: '...'}
  // Returns appVersionInfo for v12.1.0 since it's the first enabled flag
  for (const flag of macAppReleaseFlags) {
    const appVersionInfoForFlag = getAppVersionInfo(appType, { key: "versionFlag", value: flag });
    if (enabledFeatures[flag] && appVersionInfoForFlag) {
      appVersionInfo = appVersionInfoForFlag;
      break; // Stop iterating once we find a match
    }
  }

  console.log("auto updatappVersionInfo====>>499",appVersionInfo);

  if (!appVersionInfo) return null;

  // Return staging feed URL if in staging mode, otherwise production feed URL
  // UnComment this line before merging to prod to make common staging feed URL
  // return import.meta.env.VITE_MODE === "staging" ? `${MAC_AUTO_UPDATE_FEED_BASE_URL}/appcast_assessprep_mac_app_update_stage.xml` : appVersionInfo.feedUrl;
  return appVersionInfo;
};

export const getAutoUpdateValues = (triggerType, appType, enabledFeatures = {}) => {
  const macAutoUpdateFeedInfo = getMacAutoUpdateFeedInfo(appType, enabledFeatures)
  if (!macAutoUpdateFeedInfo) return null;

  const currentAppVersion = getAppVersion(appType)

  const updateType = 
    getAutoUpdateType(triggerType, appType, enabledFeatures)
  const feedUrl = macAutoUpdateFeedInfo.feedUrl
    // import.meta.env.VITE_MODE === "staging" 
    //   ? `${MAC_AUTO_UPDATE_FEED_BASE_URL}/appcast_assessprep_mac_app_update_stage.xml` 
    //   : macAutoUpdateFeedInfo.feedUrl;
  return (
    isNewAppVersion(currentAppVersion, "12.1.0") 
      ? {
          updateType: updateType,
          feedUrl: feedUrl,
        } 
      : updateType
  )
};


export const isTabFeatureSupported = (appType) => {
  var allowNewtab = false
  const currentSystemAppVersion = getAppVersion(appType)
  const deviceInfo = getDeviceInfo(appType);
  const deviceOsVersion = deviceInfo.osVersion;
  console.log("os======>",deviceOsVersion)
  // NO ios support yet
  //  if (appType === "ios" && isNewAppVersion(appVersion,"2.0.1")) {
  //   allowNewtab = true
  // }
  // const latestAppVersion = getLatestAppVersion(appType)

  // if(!latestAppVersion || !currentSystemAppVersion){
  //   return false;
  // }
  if (appType === "ios") {
    allowNewtab = false;
  }
  else if (
         appType === "mac" &&
         isNewAppVersion(currentSystemAppVersion, "10.1.0") &&
         isNewAppVersion(deviceOsVersion, "11.0.0") 
       ) {
         //We are allowing the new tab feature from 10.1.0 for mac app
         allowNewtab = true;
       } else if (
         appType === "seb" &&
         isNewAppVersion(currentSystemAppVersion, "3.3.2")
       ) {
         //We are allowing the new tab feature from 3.3.2 for seb app
         allowNewtab = true;
       }
   
  return allowNewtab;
};

export const isNewAppVersionLegacy = (appVersion,compareVersion) =>{

  if(!appVersion || !compareVersion){
    return false
  }
  
 //app budle version formate 1.0.1
  var appVersionBundle = appVersion.replace(".","") 
  var compareVersionBundle = compareVersion.replace(".","")
  var isNewer = false
  if(appVersionBundle >= compareVersionBundle){
    isNewer = true
  }
  return isNewer;
}
export const isVideoUrlAllowedInsideApp = (url, appType) => {
  let isNewTabSupported = appType === "web" ? true : isTabFeatureSupported(appType)
  let isVideoUrlAllowed = false
  const videoPlatformUrlsRegExp = {
    "ios": getRegexExpFor("zoom"),
    "mac": getRegexExpFor("zoom_or_meet"),
    "seb": getRegexExpFor("zoom"),
    // "seb": getRegexExpFor("zoom_or_meet"),
    // "cros": "" // not supporting for now
  }
  const videoPlatformUrlsRegExpForAppType = new RegExp(videoPlatformUrlsRegExp[appType])
  if(videoPlatformUrlsRegExpForAppType){
    isVideoUrlAllowed = videoPlatformUrlsRegExpForAppType.test(url)
  }
  return isNewTabSupported && isVideoUrlAllowed;
}
export const getWhiteListUrls = (experienceSettings) => {
  // Still getting used for the schools for which dynamic flag not enabled
  const whitelistedUrlsRegex =
    "^https://((assessprep.(com|cn))|(.*?.assessprep.(com|cn))|(.*?.managebac.(com|cn))|(.*?.faria.(org))|(.*?.fariaedu.(com))|(.*?.vimeo.com)|(vimeo.com)|(.*?.jit.si)|(login.(microsoftonline|live).com)|(.*?.zoom.*?)|(meet.(google).(com|cn))|(apps.(google).(com|cn))|(accounts.(google).(co(m|(.([a-z]{2}))))))(/.*)?$";

  const lockdownConfig = experienceSettings.lockdown_config || {};
  const finalWhitelistRegex =
    lockdownConfig.whitelist_urls_regex || whitelistedUrlsRegex;

  return finalWhitelistRegex;
};

export const checkIfShouldDispatchChangeSecurity = (appType, experienceSettings) => {
  const currentAppVersion = getAppVersion(appType);

  // !isNewAppVersion(currentAppVersion, "4.0.0") checks if the app version is lower than 4.0.0.
  // In the iPad (iOS) app version 4.0.0 and above, session end is allowed only when triggered by the session end button.
  // Note: The native session end button appears on all screens as part of the bottom navigation bar.
  // Therefore, we want to session termination (changeSecurity = false) triggered via the session end button only.
  return experienceSettings.mode === "offline" && ((appType === "mac" && !isNewAppVersion(currentAppVersion, "12.0.0")) ||
  (appType === "ios" && !isNewAppVersion(currentAppVersion, "4.0.0")));
} 

export const checkIfUnsupportedApp = (appType, enabledFeatures, currentUser) => {
  // Check if unsupported app flag is enabled in features
  const isDisableUnsupportedApps = enabledFeatures.disable_unsupported_apps;
  const deviceInfo = getDeviceInfo(appType);
  
  // Check if user has legacy Mac v9.0.2 accommodation enabled
  const isLegacyMacVersion902Allowed = currentUser?.custom_fields?.accommodations?.legacy_ap_mac_9_0_2_allowed;
  
  // Get version status for current app/browser
  const appVersionInfo = getAppVersionInfo(appType, { key: "version", value: deviceInfo.appVersion });

  // App is unsupported if:
  // 1. Unsupported app flag is enabled AND
  // 2. Either app version is unsupported OR browser is unsupported AND
  // 3. Not using legacy Mac v9.0.2 with accommodation enabled
  return (
    isDisableUnsupportedApps && 
    (appVersionInfo?.status === "unsupported" || browserUnsupported(appType, deviceInfo)) &&
    !(isLegacyMacVersion902Allowed && appVersionInfo?.version === "9.0.2")
  );
};