import React from 'react';
import { Button, Result } from "antd";
import { appTypeSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { appRegionSelector, enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
import QuitBtn from '../QuitBtn';
import "./UnsupportedVersionNotice.scss"
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { CheckAndSetAppType } from '/src/App/OfflineApp/CheckAndSetAppType';
import { browserUnsupported, getAppVersion, getDeviceInfo, isNewAppVersion } from '/src/App/OfflineApp/offlineAppHelper';
import UpdateAppButton from '/src/views/OfflineApps/UpdateAppButton';

const UnsupportedVersionNotice = () => {
  const dispatch = useDispatch()
  const mrIntl = useTranslate();
  const appType = useSelector(appTypeSelector());
  const currentAppVersion = getAppVersion(appType)
  const deviceInfo = getDeviceInfo(appType);
  const isBrowserUnsupported = browserUnsupported(appType, deviceInfo);
  const appTypeMessages = {
    ios: mrIntl("UnsupportedVersionNotice.you_are_using_an_unsupported_version_of_the_application"),
    seb: mrIntl("UnsupportedVersionNotice.this_version_of_the_assessprep_lockdown_app_seb_is_no_longer_supporetd"),
    mac: isNewAppVersion(currentAppVersion, "9.0.0") ? mrIntl("UnsupportedVersionNotice.you_are_using_an_unsupported_version_of_the_application_please_update_the_latest") : mrIntl("UnsupportedVersionNotice.this_version_of_the_assessprep_lockdown_app_is_no_longer_supported_download")
  }

  let unsupportedAppVersionNoticeMessage =
    isBrowserUnsupported
      ? mrIntl("UnsupportedVersionNotice.this_version_of_the_web_browser_is_no_longer_supported")
      : appTypeMessages[appType];

  return (
    <div className='unsupported-app'>
      {/* after refshing this page checkAndSetApp component is not loaded , apptype is undefined that's why i am using this component */}
      <CheckAndSetAppType />
      <Result
        status="500"
        title={mrIntl("UnsupportedVersionNotice.update_required")}
        subTitle={unsupportedAppVersionNoticeMessage}
        extra={
          <>
            <QuitBtn />
            <UpdateAppButton />
          </>
        }
      />
    </div>
  );
};

export default UnsupportedVersionNotice;