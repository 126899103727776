import React from "react";
import { Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { appRegionSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { checkAutoUpdateSupported, getAppVersion, getAutoUpdateValues, getOfflineAppDownloadUrl, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux"
import { message } from "/src/components/UI/AntdAppHelper";

export const dispatchToUpdateNativeMacApp = (
  triggerType,
  appType, 
  appRegion,
  enabledFeatures, 
  dispatch,
  callbackForAutoUpdateFailOrCompletion,
) => {
  // Only proceed if this is a Mac app with auto-updates enabled
  if (appType !== "mac") {
    return;
  }

  const isAutoUpdateEnabled = enabledFeatures.allow_auto_update_mac_app;
  const isAutoUpdateSupported = checkAutoUpdateSupported(appType, enabledFeatures);

  if (!isAutoUpdateEnabled || !isAutoUpdateSupported) {
    callbackForAutoUpdateFailOrCompletion?.(appType, appRegion, dispatch, enabledFeatures);
    return;
  }

  const appUpdateValues = 
    getAutoUpdateValues(triggerType, appType, enabledFeatures);

  if (!appUpdateValues) {
    return;
  }

  dispatch(
    offlineAppActions.executeMethod(
      [{
        key: "startUpdate",
        value: JSON.stringify(appUpdateValues),
        errorCallback: () => callbackForAutoUpdateFailOrCompletion?.(appType, appRegion, dispatch, enabledFeatures)
      }],
      {
        errorCallback: () => callbackForAutoUpdateFailOrCompletion?.(appType, appRegion, dispatch, enabledFeatures)
      }
    )
  );
}

export const dispatchToDownloadNativeMacApp = (appType, appRegion, dispatch, enabledFeatures) => {
  const downloadUrl = getOfflineAppDownloadUrl(appType, appRegion, enabledFeatures);

  if (!downloadUrl) {
    message.error("CommonText.something_went_wrong_please_try_again");
    return;
  } 

  dispatch(
    offlineAppActions.executeMethod([
      {
        key: "openLinkInBrowser", // allow open url in new browser for earlier version (existing functionality) for mac app
        value: downloadUrl,
      },
    ])
  );
}

export const UpdateAppButton = () => {
  const dispatch = useDispatch();
  const appType = useSelector(appTypeSelector());
  const appRegion = useSelector(appRegionSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const currentAppVersion = getAppVersion(appType)
  const mrIntl = useTranslate();

  const callbackForAutoUpdateFailOrCompletion = (appType, appRegion, dispatch, enabledFeatures) => {
    dispatchToDownloadNativeMacApp(appType, appRegion, dispatch, enabledFeatures);
  }
  const handleClick = () => {
    dispatchToUpdateNativeMacApp(
      "on_click",
      appType, 
      appRegion, 
      enabledFeatures, 
      dispatch, 
      callbackForAutoUpdateFailOrCompletion
    );
  };

  const shouldShowUpdateButton = 
    appType === "mac" && 
    isNewAppVersion(currentAppVersion, "9.0.0");

  return (
    shouldShowUpdateButton 
    ? (<Button
        type="primary"
        onClick={handleClick}
      >
        {mrIntl("CommonText.update_app")}
      </Button>)
    : null
  );
};

export default UpdateAppButton;
